import { Button } from "@/components/ui/button.tsx";
import { Home } from "lucide-react";
import { Link } from "wouter";

/**
 * Screen body for a page that has not yet been implemented.
 */
export const ScreenComingSoon = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full mt-40">
      <div className="prose text-center">
        <p
          className="text-4xl font-bold text-transparent"
          // Not using tailwind for this - it's a silly one off
          style={{
            backgroundImage: "linear-gradient(to right, #9333ea, #ec4899, #ef4444, #eab308, #22c55e, #3b82f6, #9333ea)",
            backgroundSize: "200% auto",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            animation: "rainbow 8s linear infinite",
          }}
        >
          Coming Soon
          <style>
            {`
              @keyframes rainbow {
                0% { background-position: 0% 50%; }
                100% { background-position: -200% 50%; }
              }
            `}
          </style>
        </p>
      </div>
      <Button asChild variant="outline" className="mt-8">
        <Link href="/" className="flex items-center gap-2">
          <Home className="w-4 h-4" />
          Go Home
        </Link>
      </Button>
    </div>
  );
};
