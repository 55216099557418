import type { ArticleSubthreadSchema } from "@/components/right-sidebar/sidebarSchema.ts";
import { Button } from "@/components/ui/button.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import { useArtifact } from "@/hooks/useArtifact.tsx";
import { RotateCw } from "lucide-react";
import { useCallback } from "react";
import { RightSidebarHeader, SidebarTextBody, SidebarTextHeading } from "../right-sidebar/elements.tsx";
import { useRightSidebar } from "../right-sidebar/hooks.tsx";

/**
 * Sidebar based on an article.
 *
 * It shows the article and allows you to create a new subthread to discuss it.
 */
export const ArticleSubthreadSidebar = ({
  threadId,
  messageId,
  artifactId,
}: ArticleSubthreadSchema) => {
  const handleNewThreadClick = useCallback(() => {
    alert("TODO");
  }, []);
  const { closeSidebar } = useRightSidebar();

  const { artifact, isLoading, error } = useArtifact({ threadId, id: messageId }, artifactId, true);
  const article = artifact?.article;

  // TODO: better loading and error states
  // https://linear.app/big-basin-labs/issue/BIG-223/better-loading-and-error-states
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (isLoading || !article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <RightSidebarHeader
        title="Sidebar"
        CTA={
          <Button
            size="sm"
            variant="outline"
            className="flex items-center gap-2 rounded-full"
            onClick={handleNewThreadClick}
          >
            <RotateCw className="h-4 w-4" />
            New thread
          </Button>
        }
        handleClose={closeSidebar}
      />

      <SidebarContent>
        <ScrollArea className="h-full">
          <div className="p-4">
            <div className="flex items-start gap-3">
              <div className="h-8 w-8 rounded-full bg-purple-500 flex items-center justify-center">
                <span className="text-white">✨</span>
              </div>
              <div className="space-y-2 flex-1">
                <SidebarTextHeading>
                  {article.url
                    ? (
                      <a href={article.url} target="_blank" rel="noopener noreferrer">
                        {article.title}
                      </a>
                    )
                    : (
                      article.title
                    )}
                </SidebarTextHeading>
                <SidebarTextBody>
                  {article.summary}
                </SidebarTextBody>
              </div>
            </div>
          </div>
        </ScrollArea>
      </SidebarContent>
    </>
  );
};
