import { Badge } from "@/components/ui/badge.tsx";
import type { GetMessageSourceResp } from "@/types/sources.ts";

const MemoryDetail = ({ source }: { source: GetMessageSourceResp }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <div className="max-w-full overflow-hidden whitespace-nowrap text-ellipsis">
          {source.memory?.content}
        </div>
        <Badge className="mr-2">Memory</Badge>
      </div>
    </div>
  );
};

export default MemoryDetail;
