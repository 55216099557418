/**
 * Contains the type and supporting data for the various potential states of the right sidebar.
 * Uses a discriminated union to allow the RightSidebar component to switch over the types and pass
 * the rest of the params to the appropriate component.
 */

import { z } from "zod";
// Ideally we'd define these next to their components, but it because they depend on the
// hooks and the hooks depend on the schema, circular dependencies are a problem.
// We'd need to define these in their own files next to their components, and be very careful about imports.
// This is less error prone and works for now.

const domainSidebarSchema = z.object({
  type: z.literal("domainSidebar"),
  threadId: z.coerce.number(),
  messageId: z.coerce.number(),
  domainId: z.string().optional(),
  publisherId: z.string().optional(),
});
export type DomainSidebarSchema = z.infer<typeof domainSidebarSchema>;

const contactListSchema = z.object({
  type: z.literal("contactList"),
  threadId: z.coerce.number().optional(),
  contactId: z.string().optional(),
});
export type ContactListSchema = z.infer<typeof contactListSchema>;

export const sidebarSchema = z.discriminatedUnion("type", [
  domainSidebarSchema,
  contactListSchema,
]);
