import { Suspense } from "react";
import { SWRConfig } from "swr";
import { SWRDevTools } from "swr-devtools";
import { Route, Router, Switch, useLocation } from "wouter";

import { isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { Loader } from "@/components/Loader.tsx";
import { useIsMobile } from "@/hooks/use-mobile.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { axiosFetcher } from "@/lib/fetchers.ts";
import { Admin } from "@/views/Admin.tsx";
import { Auth } from "@/views/Auth.tsx";
import { Category } from "@/views/Category.tsx";
import { ContactScreen } from "@/views/Contact.tsx";
import { Home } from "@/views/Home.tsx";
import { Memories } from "@/views/Memories.tsx";
import { NotFound } from "@/views/NotFound.tsx";
import { Relationships } from "@/views/Relationships.tsx";
import { Settings } from "@/views/Settings.tsx";
import { Source } from "@/views/Source.tsx";
import { Thread } from "@/views/Thread.tsx";
import { Threads } from "@/views/Threads.tsx";
import { useAtom } from "jotai";
import { useHotkeys } from "react-hotkeys-hook";
/**
 * swr-devtools's docs claim you don't need this wrapper on swr 2+, but it seems to be required.
 * https://github.com/koba04/swr-devtools/issues/133#issuecomment-2402229707
 *
 * This component is a thin wrapper that bails on mounting them outside of dev.
 */
const SWRDevtoolsWrapper = ({ children }: { children: React.ReactNode }) => {
  if (!import.meta.env.DEV) {
    return <>{children}</>;
  }

  return (
    <SWRDevTools>
      {children}
    </SWRDevTools>
  );
};

export const App = () => {
  const [, setLocation] = useLocation();
  const { userContext, isLoading } = useCurrentUserContext();
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);

  useHotkeys("b", () => {
    setIsNavSidebarOpen(!isNavSidebarOpen);
  });

  // register size change to update isMobile atom
  useIsMobile();

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (!userContext) {
    return <Auth />;
  }

  return (
    <SWRDevtoolsWrapper>
      <SWRConfig
        value={{
          onError: (error) => {
            if (error.status === 401 || error.status === 403) {
              setLocation("/auth", { replace: true });
            }
          },
          fetcher: axiosFetcher,
          refreshInterval: 900000,
          revalidateOnFocus: true,
          revalidateOnReconnect: true,
        }}
      >
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route component={Home} path="/" />
              <Route component={Auth} path="/auth" />
              <Route component={Threads} path="/threads" />
              <Route component={Home} path="/home" />
              <Route component={Thread} path="/threads/:threadId" />
              <Route component={ContactScreen} path="/contacts/:contactId" />
              <Route
                component={Source}
                path="/threads/:threadId/messages/:messageId/sources/:sourceId"
              />
              <Route component={Admin} path="/admin" />
              <Route component={Category} path="/categories/:categoryId" />
              <Route component={Relationships} path="/relationships" />
              <Route component={Memories} path="/memories" />
              <Route component={Settings} path="/settings" />
              <Route component={NotFound} path="*" />
            </Switch>
          </Suspense>
        </Router>
      </SWRConfig>
    </SWRDevtoolsWrapper>
  );
};
