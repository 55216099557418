import { axiosPostFetch } from "@/lib/fetchers.ts";
import type { GetLabelResp } from "@/types/label.ts";
import type { GetThreadResp, ThreadStatus, ThreadType } from "@/types/thread.ts";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import useSWRInfinite, { type SWRInfiniteResponse } from "swr/infinite";
import useSWRMutation from "swr/mutation";
import { useLabels } from "./useLabels.tsx";
export const THREAD_PAGE_SIZE = 5;

type FormattedLabel = {
  name: string;
  textColor?: string;
  backgroundColor?: string;
};

type FormattedThread = GetThreadResp & {
  formattedLabels?: FormattedLabel[];
};

export const findAndFormatLabel = (labelMapping: GetLabelResp[], label: string): FormattedLabel | undefined => {
  const found = labelMapping.find((lm) => lm.id === label);
  const toFilter = ["CATEGORY", "UNREAD", "SENT", "INBOX", "IMPORTANT"];
  const startsWithFilter = ["CATEGORY"];
  if (
    !found || !found.name ||
    toFilter.includes(found.name) ||
    startsWithFilter.some((prefix) => found.name?.startsWith(prefix))
  ) {
    return undefined;
  }

  return {
    name: found.name.toLowerCase().charAt(0).toUpperCase() +
      found.name.toLowerCase().slice(1),
    textColor: found.textColor ?? undefined,
    backgroundColor: found.backgroundColor ?? undefined,
  };
};

export const useThreads = ({
  status,
  type,
  order,
  limit = THREAD_PAGE_SIZE,
}: {
  status?: ThreadStatus;
  type?: ThreadType;
  order?: "asc" | "desc";
  limit?: number;
} = {}) => {
  const getThreadsUrl = (page: number, previousPageData: Array<GetThreadResp>) => {
    if (previousPageData && previousPageData.length < limit) {
      return null;
    }
    let url = `/a/threads`;
    const queryParams = new URLSearchParams();
    if (limit) {
      queryParams.set("limit", limit.toString());
    }
    if (status) {
      queryParams.set("status", status);
    }
    if (type) {
      queryParams.set("type", type);
    }
    queryParams.set("page", page.toString());
    if (order) {
      queryParams.set("order", order);
    }
    url += `?${queryParams.toString()}`;
    return url;
  };

  // Get all labels
  const {
    labels,
    isLoading: isLabelsLoading,
    error: labelsError,
  } = useLabels();

  // Get all threads
  const {
    data,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    isValidating,
  }: SWRInfiniteResponse<Array<GetThreadResp>> = useSWRInfinite<Array<GetThreadResp>>(
    getThreadsUrl,
    {
      refreshInterval: 30000,
    },
  );

  // Handle Loading Labels and Errors on Labels
  let errorToReturn = error;
  if (labelsError) {
    errorToReturn = labelsError;
  }

  let isLoadingToReturn = isLoading;
  if (isLabelsLoading) {
    isLoadingToReturn = true;
  }

  // Put in the formatted labels for each thread
  const toReturn = useMemo<FormattedThread[][]>((): FormattedThread[][] => {
    if (isLoadingToReturn || errorToReturn || !data) {
      return [];
    }
    return data.map((page) => {
      return page.map((thread): FormattedThread => {
        return {
          ...thread,
          formattedLabels: thread.labels?.map((label) => findAndFormatLabel(labels, label)).filter((
            label,
          ): label is FormattedLabel => label !== undefined),
        };
      });
    });
  }, [data, labels, isLoadingToReturn, errorToReturn]);

  const snoozeThread = async (threadId: number) => {
    await axiosPostFetch(`/a/threads/${threadId}/snooze`, {});
    mutate();
  };

  return {
    error: errorToReturn,
    isLoading: isLoadingToReturn,
    mutate,
    threadPages: toReturn,
    isValidating,
    size,
    setSize,
    snoozeThread,
  };
};

export const useCategoryThread = ({ type }: { type: ThreadType }) => {
  const url: string = `/a/category_thread/${type}`;

  const { data: thread, mutate, isLoading, error } = useSWR<GetThreadResp>(url);

  const updateVistedAt = useCallback(
    async () => {
      if (!thread) {
        return;
      }
      const response = await axiosPostFetch(`/a/threads/${thread.id}/visited`, {
        arg: {},
      });

      if (!thread.isSeen) {
        mutate();
      }
      return response.data;
    },
    [mutate],
  );

  // Archive mutation
  const archiveMutation = useSWRMutation(
    `/a/threads/${thread?.id}/archive`,
    async (url: string) => {
      const response = await axiosPostFetch<void, Pick<GetThreadResp, "status" | "id">>(url, {});
      return response.data;
    },
    {
      // Update the thread cache with the new status
      onSuccess: (newThread) => {
        mutate(
          (thread): GetThreadResp | undefined => thread ? { ...thread, status: newThread.status } : undefined,
          { revalidate: true },
        );
      },
    },
  );

  return {
    isLoading,
    error,
    thread,
    updateVistedAt,
    mutate,
    archiveMutation,
  };
};

export const useTopicThread = ({ threadId }: { threadId: number }) => {
  const url: string = `/a/threads/${threadId}`;

  const { data: thread, mutate, isLoading, error } = useSWR<GetThreadResp>(url);

  // Archive mutation
  const archiveMutation = useSWRMutation(
    `/a/threads/${threadId}/archive`,
    async (url) => {
      const response = await axiosPostFetch<void, Pick<GetThreadResp, "status" | "id">>(url, {});
      return response.data;
    },
    {
      // Update the thread cache with the new status
      onSuccess: (newThread) => {
        mutate(
          (thread): GetThreadResp | undefined => thread ? { ...thread, status: newThread.status } : undefined,
          { revalidate: true },
        );
      },
    },
  );

  return {
    isLoading,
    error,
    thread,
    archiveMutation,
    mutate,
  };
};
