import Markdown from "@/components/markdown/Markdown.tsx";
import { useCategoryThread } from "@/hooks/useThreads.tsx";
import { type ThreadType } from "@/types/thread.ts";
import { AppWindow, Newspaper } from "lucide-react";
import { useCallback } from "react";
import { useLocation } from "wouter";

const ThreadCategoryCard = ({ category }: { category: string }) => {
  const [_, setLocation] = useLocation();
  const {
    thread,
    isLoading: isThreadsLoading,
    error: threadsError,
  } = useCategoryThread({ type: category as ThreadType });

  const handleCategoryClick = useCallback(() => {
    setLocation(`/categories/${category}`);
  }, [setLocation]);

  if (threadsError || isThreadsLoading) {
    return null;
  }

  let icon = <></>;
  let name = "";
  switch (category) {
    case "news":
      name = "News";
      icon = <Newspaper size={16} />;
      break;
    case "updates":
      name = "Updates";
      icon = <AppWindow size={16} />;
      break;
  }

  return (
    <div
      className="flex-1 p-4 hover:cursor-pointer transition-all
                duration-300 rounded-2xl border lg:min-w-64 lg:w-86 lg:max-w-86"
      onClick={handleCategoryClick}
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-2 items-center font-normal">
          <div>{icon}</div>
          <div className="font-semibold">{name}</div>
          {!thread?.isSeen && (
            <div className="ml-auto relative flex h-3 w-3">
              <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75">
              </div>
              <div className="relative inline-flex rounded-full h-3 w-3 bg-sky-500">
              </div>
            </div>
          )}
        </div>
        <Markdown className="overflow-hidden prose-sm line-clamp-2 leading-tight">{thread?.summary}</Markdown>
      </div>
    </div>
  );
};

export default ThreadCategoryCard;
