import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { ChatTextarea, ChatTextareaPlaceholder } from "@/components/messages/ChatTextarea.tsx";
import { LoadingMessage, MessageItem } from "@/components/messages/MessageItem.tsx";
import { TimestampHeader } from "@/components/messages/TimestampHeaderItem.tsx";
import { usePrevious } from "@/hooks/usePrevious.tsx";
import { warnUnsupportedValue } from "@/lib/error.ts";
import type { GetMessageListResp, TimestampHeaderItem } from "@/types/messages.ts";
import { standardGutterStyles } from "@/views/ViewWrapper.tsx";
import React, { type CSSProperties, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useMessageList } from "../../hooks/useMessageList.tsx";
import { ArtifactGroupItem } from "../artifacts/ArtifactItem.tsx";
import { MessageListRow } from "./MessageListRow.tsx";

interface MessageListProps {
  threadId: number;
  className?: string;
  limit?: number;
  onEscapeKeyPressed?: () => boolean;
}

/**
 * Ensure that when new items are added to the beginning of the list, the visible content doesn't change.
 *
 * Because our infinite scroll loads in more items at the top, by default when it re-renders the browser maintains the
 * same distance from the top of the scroll container. So the scroll bar won't move, but all the sudden the user
 * is seeing items from earlier in the list.
 *
 * This hook inverts that - it modifies the scroll position in order to keep the content still.
 */
const useFixedScrollDuringLoadMore = (
  items: GetMessageListResp["items"],
  scrollContainerRef: React.RefObject<HTMLElement>,
) => {
  const prevItems = usePrevious(items);
  const prevScrollContainerHeight = useRef<number | null>(null);

  // Supporting effect to track the scroll container height. By the time the useLayoutEffect below runs,
  // the scroll container height is updated, so to compare against the previous height, we store it here.
  useEffect(() => {
    if (scrollContainerRef.current) {
      prevScrollContainerHeight.current = scrollContainerRef.current.scrollHeight;
    }

    // Technically, there's a bug here - if the height changes in between the prev render cycle (e.g. due to scroll view children
    // loading data and re-rendering themselves) and the layout effect below triggering, we'll use an outdated height for the scroll math.
    // However, that doesn't happen at time of writing. If we did want to solve that, we could e.g. add an inner content wrapper
    // inside the scroll container, put a ref on it, and add an resize observer on that (need the wrapper, scroll container height doesn't change).
    // But that wouldn't help us for other content jumps due to async loading.
    // Basically, if the children are arbirarily changing their height, we need to rethink this, but we should proably try to avoid that.
  });

  // When the list items change, adjust the scroll position so the visible content does not change.
  // We want to use a layout effect to ensure this happens before the paint, so the user does not see a flicker of different content
  // on slower devices.
  useLayoutEffect(() => {
    // Don't run when initially mounting the list, that has different autoscroll behavior
    if (!prevItems?.length) return;

    // Only run this if we've added items to the start of the list (and we have our ref)
    if (prevItems?.[0] === items?.[0] || !scrollContainerRef.current) {
      return;
    }

    const oldScrollHeight = prevScrollContainerHeight.current ?? 0;

    // This will run immediately after the DOM is updated with the new items. So the scrollContainer ref can give us the height of
    // the scroll container with the new items in place.
    const scrollContainer = scrollContainerRef.current;
    const currClientHeight = scrollContainer.clientHeight;
    const currScrollHeight = scrollContainer.scrollHeight;

    // Read scroll position here, not when the request for data fires or a render runs, so we have the most up to date value.
    // Otherwise, if a user scrolls after the request is fired/render cycle/etc, it would be outdated.
    const currScrollTop = scrollContainer.scrollTop;

    // NOTE: without `overflow-anchor: "none"`, this scrollContainer.scrollTop behaves inconsistently.
    // - If you are scrolled all the way to the top when this hook runs, scrollTop === 0. (Perhaps it's jumping to the parent scroll container?)
    // - If you are even 1px lower, scrollTop is basically newScrollTop as currently calculated, it's the value that we are manually moving to in this hook.
    //
    // overflow-anchor is supposed to do the scroll jumping for us without JS, but
    // A. it's not supported in all browsers
    // B. even with it enabled we need to scroll in JS
    // C. it has this inconsistency when it is enabled
    // So we just disable it below, and do the math on the offset ourselves.
    // If we ever need to re-enable it, we can patch this hook by using a scroll event listener in a separate hook to track the scroll position in ref.
    // @see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-anchor

    const scrollDelta = currScrollHeight - oldScrollHeight;
    // Ensure we don't over-scroll when near the bottom
    const maxScroll = currScrollHeight - currClientHeight;
    const newScrollTop = Math.min(currScrollTop + scrollDelta, maxScroll);

    console.debug("Scroll container values:", {
      currScrollTop,
      newScrollTop,
      oldScrollHeight,
      currScrollHeight,
      scrollDelta,
      maxScroll,
    });

    // Only adjust if there's actually a change in height
    if (newScrollTop !== currScrollTop) {
      console.debug("Adjusting scrollTop to:", newScrollTop);
      scrollContainer.scrollTop = newScrollTop;
    }
  }, [items, prevItems, scrollContainerRef]);

  const style: CSSProperties = {
    // see NOTE above on currScrollTop
    overflowAnchor: "none",
  };

  return {
    scrollContainerRef,
    scrollContainerStyles: style,
  };
};

export const MessageList = ({
  threadId,
  className,
  limit,
  onEscapeKeyPressed,
}: MessageListProps) => {
  const {
    items,
    isLoading: isMessagesLoading,
    isSending: isSendingMessage,
    error: messagesError,
    sendMessage,
    setListItemVisibility,
    hasPreviousPage,
    loadMore,
  } = useMessageList({ threadId, limit });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollContainerStyles } = useFixedScrollDuringLoadMore(items, scrollContainerRef);

  /*
   * Initial scroll position handling - the server can pass down a `isInitialItem` flag
   * on the first page of list items. If present, we scroll to it immediately.
   *
   * We use a layout effect to execute this before the paint, so the user does not see a flash content
   * from a different scroll position.
   *
   * Uses a ref to ensure we don't run it when future infinite scroll pages are fetched.
   */
  const hasScrolledToInitialElement = useRef<boolean>(false);
  const initialScrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (hasScrolledToInitialElement.current) {
      return;
    }
    if (!items?.length) return;

    if (initialScrollRef.current) {
      initialScrollRef.current.scrollIntoView({ behavior: "instant", block: "start" });
    } else if (scrollContainerRef.current) {
      // if there is no initial item to scroll to, just scroll to the bottom of the container
      const container = scrollContainerRef.current;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "instant",
      });
    }

    // set the ref so we don't repeat the scroll.
    // Even if somehow we didn't perform a scroll, we still don't want this firing later
    hasScrolledToInitialElement.current = true;
  }, [items]);

  /**
   * Infinite scroll "load more" trigger.
   *
   * Uses an intersection observer under the hood.
   */
  const [triggerLoadMoreRef] = useInView({
    threshold: 0.1,
    skip: isMessagesLoading || !hasPreviousPage,
    rootMargin: "0px 0px 400px 0px", // the 400px gives us buffer, so it starts loading before it's actually visible
    onChange: (loaderInView) => {
      if (messagesError || isMessagesLoading) return;
      if (!hasPreviousPage) {
        return;
      }
      if (loaderInView) {
        loadMore();
      }
    },
  });

  const handleEnterKeyPressed = useCallback(
    (value: string) => {
      sendMessage(value);
    },
    [sendMessage],
  );

  if (messagesError) return <Error error={messagesError} />;
  if (isMessagesLoading || !items?.length) return <Loader />;

  // Need to be able to compare these timestamps to one another
  let prevTimestampHeaderItem: TimestampHeaderItem | undefined;

  return (
    <div className={`flex-1 flex-col ${className} relative`} id="message-list-container">
      <div
        // Do not set vertical margin on this container - it can cause a jump when the infinite scroll runs
        // If we're not scrolled at the way to the top when the new data loads in and we paint the new elements
        className="h-full outline-none overflow-y-auto relative"
        style={scrollContainerStyles}
        ref={scrollContainerRef}
      >
        {/* We use this inner absolutely positioned container to constrain the scroll container to the main section of the page, avoiding scrolling under the header.  */}
        {/* Width goes here instead of in the parent so the scrollbar doesn't wind up floating on larger screens. */}
        <div className={`absolute h-full w-full lg:max-w-[44rem] flex flex-col ${standardGutterStyles}`}>
          {/* Innermost wrapper gives the sticky header something to stick to */}
          <div className="flex flex-col flex-1">
            {/* LoadMore trigger */}
            {hasPreviousPage && (
              <div ref={triggerLoadMoreRef} className="h-4">
                <Loader />
              </div>
            )}

            {/* Message list */}
            {items.map((item) => {
              switch (item.itemType) {
                case "timestampHeader": {
                  const itemElement = (
                    <MessageListRow
                      key={item.key}
                      item={item}
                      scrollContainerRef={scrollContainerRef}
                      sticky
                      setListItemVisibility={setListItemVisibility}
                      scrollTargetRef={item.isInitialItem ? initialScrollRef : undefined}
                    >
                      <TimestampHeader
                        timestamp={item.timestamp}
                        prevTimestamp={prevTimestampHeaderItem?.timestamp}
                      />
                    </MessageListRow>
                  );

                  prevTimestampHeaderItem = item;

                  return itemElement;
                }
                case "artifactMessageSummary": {
                  const itemElement = (
                    <MessageListRow
                      key={item.key}
                      item={item}
                      author={item.author}
                      scrollContainerRef={scrollContainerRef}
                      setListItemVisibility={setListItemVisibility}
                    >
                      <MessageItem message={item.message} author={item.author} />
                    </MessageListRow>
                  );
                  return itemElement;
                }
                case "topicMessage": {
                  const itemElement = (
                    <MessageListRow
                      key={item.key}
                      item={item}
                      author={item.author}
                      scrollContainerRef={scrollContainerRef}
                      setListItemVisibility={setListItemVisibility}
                    >
                      <MessageItem message={item.message} author={item.author} />
                    </MessageListRow>
                  );
                  return itemElement;
                }
                case "artifactGroup": {
                  const itemElement = (
                    <MessageListRow
                      key={item.key}
                      item={item}
                      scrollContainerRef={scrollContainerRef}
                      setListItemVisibility={setListItemVisibility}
                    >
                      <ArtifactGroupItem
                        messageId={item.messageId}
                        threadId={item.threadId}
                        group={item.artifactGroup}
                      />
                    </MessageListRow>
                  );
                  return itemElement;
                }
                default:
                  warnUnsupportedValue(item);
                  return null;
              }
            })}

            {
              /*
               * Insert the placeholder element as the list footer. Allows the chat textarea to overlap content
               * without blocking the last message when scrolled to the bottom.
               */
            }
            <ChatTextareaPlaceholder />
          </div>
        </div>
      </div>

      {isSendingMessage && <LoadingMessage />}

      <div className="mt-auto fixed bottom-0 w-full">
        <ChatTextarea
          onEnterKeyPressed={handleEnterKeyPressed}
          isSendingMessage={isSendingMessage}
          disabled={isMessagesLoading || isSendingMessage}
          onEscapeKeyPressed={onEscapeKeyPressed}
        />
      </div>
    </div>
  );
};
