import { z } from "zod";

export const threadStatus = z.enum([
  "completed",
  "in-progress",
  "to-review",
  "snoozed",
]);

export const threadType = z.enum(["topics", "updates", "events", "news"]);

export const threadParticipant = z.object({
  id: z.string(),
  name: z.string(),
  picture: z.string().optional(),
});

export const getThreadResp = z.object({
  id: z.number(),
  status: threadStatus,
  summary: z.string().nullable(),
  title: z.string(),
  type: threadType,
  lastReceivedAt: z.coerce.date(),
  lastVisitedAt: z.coerce.date().nullable(),
  labels: z.array(z.string()).optional().nullable(),
  unreadCount: z.number(),
  isSeen: z.boolean(),
  snoozeUntil: z.coerce.date().nullable(),
  snoozeJobId: z.string().nullable(),
  participants: z.array(threadParticipant).optional().nullable(),
});

export type GetThreadResp = z.infer<typeof getThreadResp>;
export type ThreadStatus = z.infer<typeof threadStatus>;
export type ThreadType = z.infer<typeof threadType>;
