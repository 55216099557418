import ThreadCards from "@/components/threads/ThreadCards.tsx";
import ThreadCategoryCard from "@/components/threads/ThreadCategoryCard.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { ViewWrapper } from "./ViewWrapper.tsx";
export const Home = () => {
  const { userContext } = useCurrentUserContext();

  const oneWayCategories = ["news", "updates"];

  const title = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <ViewWrapper title={title} titleClassName="text-sm text-muted-foreground">
      <div className="flex flex-col gap-9 mb-24 mt-4">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl font-semibold lg:max-w-176">
            Good morning, {userContext?.user.contact.name}!
          </h1>
          <div className="flex-1 flex-col gap-2">
            <div className="text-gray-500 text-lg leading-lg lg:w-176 lg:max-w-176">
              Check out shipping and appointment confirmations, work emails, and some social notifications. Plus, there
              are bank statements, renewal notices, and a few exclusive offers waiting for you!
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-start items-stretch gap-4">
          {oneWayCategories.map((category) => <ThreadCategoryCard category={category} key={category} />)}
        </div>
        <div className="flex flex-col lg:flex-row justify-start items-stretch">
          <ThreadCards />
        </div>
      </div>
    </ViewWrapper>
  );
};
export default Home;
