import { messageListScrollPositionAtom } from "@/atoms/messagesAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useCategoryThread } from "@/hooks/useThreads.tsx";
import type { ThreadType } from "@/types/thread.ts";
import { StatusBadge } from "@/views/Thread.tsx";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { CircleCheck } from "lucide-react";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useRoute } from "wouter";
import { MessageList } from "../components/messages/MessageList.tsx";

export const Category = () => {
  const [_location, setLocation] = useLocation();
  const [matchThread, paramsThread] = useRoute("/categories/:category");
  const [_, setMessageListScrollPosition] = useAtom(messageListScrollPositionAtom);

  const { thread, isLoading, error, updateVistedAt, archiveMutation } = useCategoryThread({
    type: paramsThread?.category as ThreadType,
  });

  useEffect(() => {
    if (!matchThread || !paramsThread || !paramsThread.category) {
      setLocation("/");
    }
  }, [matchThread, paramsThread, setLocation]);

  useEffect(() => {
    if (thread) {
      updateVistedAt();
    }
  }, []);

  const handleBack = useCallback(() => {
    setMessageListScrollPosition(-1);
    return true;
  }, [setMessageListScrollPosition]);

  const title = useMemo(() => {
    if (!thread) {
      return "";
    }
    return thread?.title?.charAt(0).toUpperCase() + thread?.title?.slice(1);
  }, [thread]);

  if (!matchThread || !paramsThread || !paramsThread.category) {
    return null;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading || !thread) {
    return <Loader />;
  }

  const handleMarkAsDone = () => {
    archiveMutation.trigger();
    window.history.back();
  };

  const rightHeaderElement = (
    <div className="flex items-center gap-2 pr-2">
      {thread?.status === "to-review"
        ? (
          <Button variant="outline" size="sm" onClick={handleMarkAsDone} disabled={archiveMutation.isMutating}>
            <CircleCheck size={16} />
            <span className="">Mark as done</span>
          </Button>
        )
        : <StatusBadge status={thread?.status || "to-review"} />}
    </div>
  );

  return (
    <ViewWrapper
      title={title}
      titleClassName="text-xl font-semibold tracking-tight"
      includeBackButton={true}
      onBack={handleBack}
      allowFullBleed={true}
      rightElement={rightHeaderElement}
    >
      <MessageList
        limit={3}
        threadId={thread.id}
        onEscapeKeyPressed={handleBack}
      />
    </ViewWrapper>
  );
};

export default Category;
