import { Badge } from "@/components/ui/badge.tsx";
import type { GetMessageSourceResp } from "@/types/sources.ts";

const DocDetail = ({ source }: { source: GetMessageSourceResp }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <p>{source.document?.name}</p>
        <Badge>Doc</Badge>
      </div>
      <div className="flex flex-col border rounded-md p-2">
        <p>{source.document?.url}</p>
      </div>
    </div>
  );
};

export default DocDetail;
