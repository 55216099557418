import { DomainSidebar } from "@/components/artifacts/DomainSidebar.tsx";
import { ContactListSidebar } from "@/components/contacts/ContactListSidebar.tsx";
import { Sidebar, SidebarProvider, useSidebar } from "@/components/ui/sidebar.tsx";
import { UnsupportedValueError } from "@/lib/error.ts";
import { useEffect, useMemo, useState } from "react";
import { useRightSidebarQueryParamState } from "./hooks.tsx";

const RightSidebarBody = () => {
  const { toggleSidebar, isMobile, open, openMobile } = useSidebar();

  // Get the sidebar state based on the URL query params
  const queryParamSidebarState = useRightSidebarQueryParamState();

  // In order to keep the sidebar content in place while it closes (so it can animate out),
  // we create a cache of the last non-null sidebar state.
  // Note that we don't actually clean this up while the sidebar is closed - there's no real need to.
  const [cachedSidebarState, setCachedSidebarState] = useState<typeof queryParamSidebarState>(null);
  useEffect(() => {
    if (queryParamSidebarState) {
      setCachedSidebarState(queryParamSidebarState);
    }
  }, [queryParamSidebarState]);

  // To prevent off by 1's with the cached state, we prefer to use the query param state when it's present,
  // This means the cached state should only be used immediately after the query param state is set to null
  const sidebarState = queryParamSidebarState ?? cachedSidebarState;

  // Open state is based on the actual query param state, so that when we close the sidebar by updating the URL,
  // We actually trigger the close.
  const shouldBeOpen = !!queryParamSidebarState;

  // We want our sidebar to be "controlled". But, while Shadcn's sidebar supports controlled state,
  // it doesn't work well on mobile (the open prop is ignored). We work around that by
  // using toggleSidebar, though the open/openMobile props make it pretty ugly.
  // TODO: maybe modify the sidebar to support controlled state better
  useEffect(() => {
    if (!isMobile && shouldBeOpen !== open) {
      toggleSidebar();
    }

    if (isMobile && shouldBeOpen !== openMobile) {
      toggleSidebar();
    }
  }, [toggleSidebar, shouldBeOpen, open, openMobile, isMobile]);

  const content = useMemo(() => {
    if (!sidebarState) {
      return null;
    }

    switch (sidebarState.type) {
      case "domainSidebar":
        return <DomainSidebar {...sidebarState} />;
      case "contactList":
        return <ContactListSidebar {...sidebarState} />;
      default:
        throw new UnsupportedValueError(sidebarState);
    }
  }, [sidebarState]);

  return (
    <Sidebar side="right" collapsible="offcanvas">
      {content}
    </Sidebar>
  );
};

/**
 * Parent component for the right sidebar. Actual content is dynamic, and is determined by query params.
 *
 * @see {@link ./README.md}
 */
export const RightSidebar = () => {
  return (
    <SidebarProvider
      // TODO: this classname is a workaround for the multi sidebar provider issues described in
      // https://github.com/shadcn-ui/ui/issues/5651. Specifically, that having 2
      // sidebars mounted at the same time introduces a ton of whitespace.
      // If this workaround causes trouble we may want to use https://github.com/shadcn-ui/ui/issues/5651#issuecomment-2549049594.
      // We don't want a Sheet, because we don't want to block the main content.
      className={`absolute top-0 right-0 left-0 bottom-0`}
      defaultOpen={false}
      style={{
        "--sidebar-width": "27rem",
        "--sidebar-width-mobile": "20rem",
      }}
    >
      <RightSidebarBody />
    </SidebarProvider>
  );
};
