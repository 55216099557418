import type { ArtifactGroupSchema } from "@/components/right-sidebar/sidebarSchema.ts";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import type { GetArtifactResp } from "@/types/artifacts.ts";
import type { ArtifactGroupItem } from "@/types/messages.ts";
import { useMessageList } from "../../hooks/useMessageList.tsx";
import {
  RightSidebarHeader,
  SidebarTextBody,
  SidebarTextHeading,
  SidebarTextSubtitle,
} from "../right-sidebar/elements.tsx";
import { useRightSidebar } from "../right-sidebar/hooks.tsx";

const GroupedArticleItem = ({ artifact }: { artifact: GetArtifactResp }) => {
  if (!artifact?.article) {
    console.error("Generalize artifact support", artifact);
    return null;
  }

  const { article } = artifact;

  return (
    <div className="space-y-2">
      <SidebarTextHeading>
        {article.url
          ? (
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              {article.title}
            </a>
          )
          : (
            article.title
          )}
      </SidebarTextHeading>
      <SidebarTextBody>{article.summary}</SidebarTextBody>
    </div>
  );
};

// TODO: this is really more of a "sender" sidebar, not an "artifact" sidebar - rework this to be more
// author based, rather than explictly expecting a group.
// https://linear.app/big-basin-labs/issue/BIG-219/sidebar-support-history
export const ArtifactGroupSidebar = ({ groupName, threadId }: ArtifactGroupSchema) => {
  const { closeSidebar } = useRightSidebar();
  const { items, isLoading, error } = useMessageList({ threadId });

  // TODO: This is pretty hacky, but when we rethink the content to be separate from the message list, we can do better.
  const item = items.find((item): item is ArtifactGroupItem =>
    "artifactGroup" in item && item.artifactGroup.groupName === groupName
  );
  const group = item?.artifactGroup;

  // TODO: better loading and error states
  // https://linear.app/big-basin-labs/issue/BIG-223/better-loading-and-error-states
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (isLoading || !group) {
    return (
      <>
        <RightSidebarHeader
          title={group?.groupName ?? "Loading..."}
          handleClose={closeSidebar}
        />
        <div>Loading...</div>
      </>
    );
  }

  return (
    <>
      {group.groupName
        ? (
          <RightSidebarHeader
            title={group.groupName}
            handleClose={closeSidebar}
          />
        )
        : null}

      <SidebarContent>
        <ScrollArea className="h-full">
          <div className="space-y-8 p-6">
            <SidebarTextSubtitle>
              TODO: date
            </SidebarTextSubtitle>
            <div className="space-y-6">
              {/* TODO: support full history https://linear.app/big-basin-labs/issue/BIG-219/sidebar-support-history */}
              {group.artifacts.map((artifact) => <GroupedArticleItem key={artifact.id} artifact={artifact} />)}
            </div>
          </div>
        </ScrollArea>
      </SidebarContent>
    </>
  );
};
