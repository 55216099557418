export const formatDateForMessageDetail = (dateValue: string): string => {
  if (!dateValue) {
    return "";
  }
  const date = new Date(dateValue);
  const today = new Date();
  if (date.getDate() === today.getDate()) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  } else if (today.getFullYear() === date.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
