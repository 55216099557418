import Markdown from "@/components/markdown/Markdown.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip.tsx";
import type { GetArtifactResp } from "@/types/artifacts.ts";
import { ArrowRight, CircleArrowOutUpRight, Copy, Dot, Share2 } from "lucide-react";
import { useCallback } from "react";
import { Link } from "wouter";
import { useRightSidebar } from "../right-sidebar/hooks.tsx";

interface ArticleArtifactItemProps {
  messageId: number;
  threadId: number;
  artifact: GetArtifactResp;
}

export const ArticleArtifactItem = (
  { messageId, threadId, artifact }: ArticleArtifactItemProps,
) => {
  const { openSidebar } = useRightSidebar();

  if (!("article" in artifact) || !artifact.article) {
    throw new Error("Article artifact item received an artifact without an article");
  }

  if (artifact.article.url?.startsWith("source:")) {
    artifact.article.url = `/threads/${threadId}/messages/${messageId}/sources/${
      artifact.article.url.replace("source:", "")
    }`;
  }

  const handleOpenSidebar = useCallback(() => {
    openSidebar({
      type: "domainSidebar",
      messageId,
      threadId,
      publisherId: artifact.article?.publisher?.id,
    });
  }, [artifact, messageId, threadId, openSidebar]);

  return (
    <div className="flex flex-col-reverse lg:flex-row gap-5">
      <div className="basis-full flex justify-start flex-col gap-3 group/article">
        <div className="flex flex-row text-lg items-center font-semibold leading-7
                  underline decoration-solid text-underline-offset-auto
                  text-underline-position-from-font">
          {artifact.article.url
            ? (
              <a
                href={artifact.article.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {artifact.article.title}
              </a>
            )
            : (
              artifact.article.title
            )}
          {!artifact.isRead && <Dot size={24} className="text-blue-500" />}
        </div>
        <div className="line-clamp-6 overflow-hidden">
          <Markdown className="leading-normal">{artifact.article.summary}</Markdown>
        </div>
        <div
          className="border rounded-full text-sm px-2 py-0.5 w-fit"
          onClick={handleOpenSidebar}
        >
          {artifact.article.publisher?.name}
        </div>
        <div
          className={`flex flex-row flex-start gap-2 text-xs text-muted-foreground invisible group-hover/article:visible`}
        >
          <Tooltip>
            <TooltipTrigger>
              <Copy size={16} />
            </TooltipTrigger>
            <TooltipContent>Copy</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <Share2 size={16} />
            </TooltipTrigger>
            <TooltipContent>Share</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <CircleArrowOutUpRight size={16} />
            </TooltipTrigger>
            <TooltipContent>New Thread</TooltipContent>
          </Tooltip>
          <div className="ml-auto">
            <Link href={`/threads/${threadId}/messages/${messageId}/sources/${artifact.source?.id}`}>
              <div className="flex flex-row items-center gap-0.5 text-foreground font-medium">
                <div>Source</div>
                <ArrowRight size={16} />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-32 lg:w-[16.375rem] lg:h-[13.25rem] flex-none border rounded-2xl">
        {artifact.article.imageUrl && (
          <img
            src={artifact.article.imageUrl.replace(/&amp;/g, "&")}
            alt="Article image"
            className="w-full h-full object-cover rounded-2xl"
          />
        )}
      </div>
    </div>
  );
};
