import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import Markdown from "@/components/markdown/Markdown.tsx";
import {
  RightSidebarHeader,
  SidebarTextBody,
  SidebarTextHeading,
  SidebarTextSubtitle,
} from "@/components/right-sidebar/elements.tsx";
import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { type DomainSidebarSchema } from "@/components/right-sidebar/sidebarSchema.ts";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import { useArtifacts } from "@/hooks/useArtifact.tsx";
import type { GetArtifactResp } from "@/types/artifacts.ts";
import { useEffect, useState } from "react";

const SidebarItem = ({ artifact }: { artifact: GetArtifactResp }) => {
  if (!artifact?.article && !artifact?.update) {
    console.error("Generalize artifact support", artifact);
    return null;
  }

  let item: {
    url: string | undefined;
    title: string | undefined;
    summary: string | undefined;
  } | null = null;
  switch (artifact.type) {
    case "article": {
      const { article } = artifact;
      item = {
        url: article?.url,
        title: article?.title,
        summary: article?.summary,
      };
      break;
    }
    case "update": {
      const { update } = artifact;
      item = {
        url: undefined,
        title: update?.title,
        summary: update?.summary,
      };
      break;
    }
    default:
      return null;
  }

  return (
    <div className="space-y-2">
      <SidebarTextHeading>
        {item.url
          ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item?.title}
            </a>
          )
          : (
            item?.title
          )}
      </SidebarTextHeading>
      <SidebarTextBody>
        <Markdown className="prose">{item?.summary}</Markdown>
      </SidebarTextBody>
    </div>
  );
};

// TODO: this is really more of a "sender" sidebar, not an "artifact" sidebar - rework this to be more
// author based, rather than explictly expecting a group.
// https://linear.app/big-basin-labs/issue/BIG-219/sidebar-support-history
export const DomainSidebar = ({ domainId, publisherId, threadId, messageId }: DomainSidebarSchema) => {
  const { closeSidebar } = useRightSidebar();
  const [groupName, setGroupName] = useState<string | undefined>(undefined);
  const { artifacts, isLoading, error } = useArtifacts({
    domainId,
    publisherId,
    threadId,
    messageId,
  });

  useEffect(() => {
    if (artifacts) {
      switch (artifacts[0].type) {
        case "article": {
          const group = artifacts[0].article?.publisher?.name;
          setGroupName(group);
          break;
        }
        case "update": {
          const group = artifacts[0].update?.domain?.name;
          setGroupName(group);
          break;
        }
        default:
          break;
      }
    }
  }, [artifacts]);

  // TODO: better loading and error states
  // https://linear.app/big-basin-labs/issue/BIG-223/better-loading-and-error-states
  if (error) {
    return <Error error={error} />;
  }
  if (isLoading || !groupName) {
    return <Loader />;
  }

  return (
    <>
      {groupName
        ? (
          <RightSidebarHeader
            title={groupName}
            handleClose={closeSidebar}
          />
        )
        : null}

      <SidebarContent>
        <div className="space-y-8 p-6">
          <SidebarTextSubtitle>
            TODO: date
          </SidebarTextSubtitle>
          <div className="space-y-6">
            {/* TODO: support full history https://linear.app/big-basin-labs/issue/BIG-219/sidebar-support-history */}
            {artifacts?.map((artifact) => <SidebarItem key={artifact.id} artifact={artifact} />)}
          </div>
        </div>
      </SidebarContent>
    </>
  );
};
