import { useEffect, useState } from "react";
import useSWR from "swr";

import { Button } from "@/components/ui/button.tsx";
import { Calendar } from "@/components/ui/calendar.tsx";
import { Input } from "@/components/ui/input.tsx";
import { getFetch, postFetch } from "@/lib/fetchers.ts";
import type { CalendarEvent } from "@/types/calendarEvent.ts";
import type { User } from "@/types/users.ts";
import type { DateRange } from "react-day-picker";

export const useUserResults = (query: null | string) => {
  const { data, isLoading } = useSWR<User[]>(
    query ? `/s/users?query=${query}` : null,
    getFetch,
  );

  return {
    isLoading,
    users: data,
  };
};

export const useEventsResults = (startDate?: Date, endDate?: Date) => {
  const { data, isLoading } = useSWR<CalendarEvent[]>(
    startDate && endDate
      ? `/s/search/events?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      : null,
    getFetch,
  );

  return {
    isLoading,
    events: data,
  };
};

export const Admin = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { users, isLoading } = useUserResults(searchQuery);
  const [usersState, setUsersState] = useState<User[]>(users || []);
  const [isLogoutLoading, setIsLogoutLoading] = useState<boolean>(false);
  const [isResetSyncLoading, setIsResetSyncLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isForceSyncLoading, setIsForceSyncLoading] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState<DateRange>();
  const { events, isLoading: isEventsLoading } = useEventsResults(
    dateRange?.from,
    dateRange?.to,
  );

  useEffect(() => {
    if (users) {
      setUsersState(users);
    }
  }, [users]);

  const handleSearch = () => {
    if (inputValue.trim()) {
      setSearchQuery(inputValue);
    }
  };

  const handleForceSync = async (userId: number) => {
    console.log("Force sync", userId);

    try {
      setIsForceSyncLoading(true);

      // Post to /a/users/force_sync
      await postFetch(`/s/users/force_sync`, {
        arg: { userId },
      });
    } finally {
      setIsForceSyncLoading(false);
    }
  };

  const handleDelete = async (userId: number) => {
    console.log("Delete", userId);

    try {
      setIsDeleteLoading(true);

      // Post to /a/users/delete
      await postFetch(`/s/users/delete`, {
        arg: { userId },
      });

      // Remove from the list
      setUsersState((prev) => prev.filter((user) => user.id !== userId));
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handleLogout = async (userId: number) => {
    console.log("Logout", userId);

    try {
      setIsLogoutLoading(true);

      // Post to /a/users/logout
      await postFetch(`/s/users/logout`, {
        arg: { userId },
      });
    } finally {
      setIsLogoutLoading(false);
    }
  };

  const handleResetSync = async (userId: number) => {
    console.log("Reset sync", userId);

    try {
      setIsResetSyncLoading(true);

      // Post to /a/users/reset_sync
      await postFetch(`/s/users/reset_sync`, {
        arg: { userId },
      });
    } finally {
      setIsResetSyncLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-left justify-left h-screen w-full p-8">
      <h1 className="text-4xl font-bold text-gray-500">Admin</h1>
      <h3 className="text-2xl font-bold text-gray-500 mt-4">Users</h3>
      <div className="flex gap-2 mb-2">Search for users by name or email:</div>
      <div className="flex gap-2 mb-4">
        <Input
          className="flex-1"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" ? handleSearch() : null}
        />
        <Button onClick={handleSearch}>Search</Button>
      </div>
      {isLoading && <p>Loading...</p>}
      {usersState && (
        <div className="space-y-4">
          {usersState.map((user) => (
            <div key={user.id} className="p-4 border rounded">
              User ID: {user.id} <br />
              Name: {user.contact.name} <br />
              Email: {user.contact.contactAddresses?.map((ca) => ca.address).join(", ")} <br />
              <div className="flex gap-2 mt-2">
                <Button
                  variant={isLogoutLoading ? "outline" : "default"}
                  onClick={() => !isLogoutLoading && handleLogout(user.id)}
                >
                  Logout
                </Button>
                <Button
                  variant={isForceSyncLoading ? "outline" : "default"}
                  onClick={() => !isForceSyncLoading && handleForceSync(user.id)}
                >
                  Force Sync
                </Button>
                <Button
                  variant={isResetSyncLoading ? "outline" : "default"}
                  onClick={() => !isResetSyncLoading && handleResetSync(user.id)}
                >
                  Force Reset Sync
                </Button>
                <Button
                  variant={isDeleteLoading ? "outline" : "destructive"}
                  onClick={() => !isDeleteLoading && handleDelete(user.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      <h3 className="text-2xl font-bold text-gray-500 mt-8">Calendar Events</h3>
      <div className="flex gap-2 mb-2">
        Search for calendar events (current user):
      </div>
      <div className="flex gap-2 mb-4">
        <Calendar
          mode="range"
          selected={dateRange}
          onSelect={setDateRange}
        />
      </div>
      {isEventsLoading && <p>Loading...</p>}
      {events && (
        <div className="space-y-4">
          {events.map((event) => (
            <div className="p-4 border rounded" key={event.eventId}>
              <strong>{event.summary}</strong> (
              {new Date(event.startTime).toLocaleString("en-US", {
                timeZone: event.timeZone,
              })} -
              {new Date(event.endTime).toLocaleString("en-US", {
                timeZone: event.timeZone,
              })})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
