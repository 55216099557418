/**
 * Contains the type and supporting data for the various potential states of the right sidebar.
 * Uses a discriminated union to allow the RightSidebar component to switch over the types and pass
 * the rest of the params to the appropriate component.
 */

import { z } from "zod";

// Ideally we'd define these next to their components, but it because they depend on the
// hooks and the hooks depend on the schema, circular dependencies are a problem.
// We'd need to define these in their own files next to their components, and be very careful about imports.
// This is less error prone and works for now.

const articleSubthreadSchema = z.object({
  type: z.literal("articleSubthread"),
  threadId: z.coerce.number(),
  messageId: z.coerce.number(),
  artifactId: z.coerce.number(),
});
export type ArticleSubthreadSchema = z.infer<typeof articleSubthreadSchema>;

const artifactGroupSchema = z.object({
  type: z.literal("artifactGroup"),
  threadId: z.coerce.number(),
  messageId: z.coerce.number(),
  groupName: z.string(),
});
export type ArtifactGroupSchema = z.infer<typeof artifactGroupSchema>;

const contactSchema = z.object({
  type: z.literal("contact"),
  contactId: z.string(),
});
export type ContactSchema = z.infer<typeof contactSchema>;

const contactListSchema = z.object({
  type: z.literal("contactList"),
  threadId: z.coerce.number(),
});
export type ContactListSchema = z.infer<typeof contactListSchema>;

export const sidebarSchema = z.discriminatedUnion("type", [
  artifactGroupSchema,
  articleSubthreadSchema,
  contactSchema,
  contactListSchema,
]);
