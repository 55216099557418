import { axiosFetcher } from "@/lib/fetchers.ts";
import type { GetUserContextResp } from "@/types/users.ts";
import useSWR from "swr";

export const useUserShow = (userId: null | string) => {
  const { data, isLoading } = useSWR<GetUserContextResp>(
    userId ? `/a/agents/${userId}` : null,
  );

  return {
    isLoading,
    user: data,
  };
};

export const useCurrentUserContext = () => {
  const { data, isLoading, error, mutate } = useSWR<GetUserContextResp>(
    "/a/users/me",
    {
      fetcher: axiosFetcher,
      errorRetryCount: 0,
    },
  );

  return {
    error,
    isLoading,
    mutate,
    userContext: data,
  };
};
