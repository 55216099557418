import useSWR from "swr";

export const useLabels = () => {
  const { isLoading, data, error } = useSWR("/a/labels");
  return {
    labels: data,
    isLoading,
    error,
  };
};
