import { Badge } from "@/components/ui/badge.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import type { Contact } from "@/types/contact.ts";
import { Mail, Phone } from "lucide-react";

export const ContactItem = ({ contact }: { contact: Contact }) => {
  const contactAddresses = contact?.contactAddresses?.map((ca) => {
    let icon = Mail;
    switch (ca.type) {
      case "emailAddress":
        icon = Mail;
        break;
      case "phone":
        icon = Phone;
        break;
    }
    return {
      ...ca,
      icon: icon,
    };
  });

  return (
    <Card className="mx-4">
      <CardHeader>
        <CardTitle className="flex flex-row items-center gap-2">
          {contact?.picture && (
            <img
              src={contact.picture}
              alt={`${contact.name}'s picture`}
              className="w-8 h-8 rounded-full"
              onError={(e) => {
                console.log(e);
              }}
            />
          )}
          {contact?.name}
          <Badge className="w-fit">
            {contact?.type
              ? contact?.type?.charAt(0).toUpperCase() +
                contact?.type?.slice(1)
              : ""}
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2">
          {contactAddresses?.map((ca) => (
            <div
              key={ca.address}
              className="flex flex-row items-center gap-2"
            >
              <ca.icon className="w-4 h-4" />
              {ca.address}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
