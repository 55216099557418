import { ArticleArtifactItem } from "@/components/artifacts/ArticleArtifactItem.tsx";
import { CountdownButton } from "@/components/artifacts/CountdownButton.tsx";
import { EmailArtifactItem } from "@/components/artifacts/EmailArtifactItem.tsx";
import { UpdateArtifactItem } from "@/components/artifacts/UpdateArtifactItem.tsx";
import { useApproveArtifact } from "@/hooks/useArtifact.tsx";
import type { GetArtifactResp } from "@/types/artifacts.ts";
import type { MessageGroupedArtifact } from "@/types/messages.ts";
import { useRightSidebar } from "../right-sidebar/hooks.tsx";

export const ArtifactItem = (
  { messageId, threadId, artifact }: { messageId: number; threadId: number; artifact: GetArtifactResp },
) => {
  const { approve, cancel, isMutating } = useApproveArtifact(
    { threadId, id: messageId },
    artifact.id,
  );

  return (
    <div>
      {artifact.email && <EmailArtifactItem email={artifact.email} />}
      {artifact.update && (
        <UpdateArtifactItem
          messageId={messageId}
          threadId={threadId}
          artifact={artifact}
          update={artifact.update}
        />
      )}
      {artifact.article && (
        <ArticleArtifactItem
          messageId={messageId}
          threadId={threadId}
          artifact={artifact}
        />
      )}
      {artifact?.approveTimeout && (
        <CountdownButton
          expiresAt={artifact.approveTimeout}
          isMutating={isMutating}
          onComplete={approve}
          onCancel={cancel}
        />
      )}
    </div>
  );
};

const GroupTitle = (
  { group, messageId, threadId }: { group: MessageGroupedArtifact; messageId: number; threadId: number },
) => {
  const { openSidebar } = useRightSidebar();
  const groupName = group.groupName;

  if (!groupName) {
    return null;
  }

  return (
    <div
      key="group-title"
      className="flex flex-row items-center gap-1 rounded-full border pr-2 py-0.5 bg-slate-100 w-fit hover:bg-slate-200 transition-colors cursor-pointer"
      onClick={() =>
        openSidebar({
          type: "artifactGroup",
          threadId,
          messageId,
          groupName,
        })}
    >
      <div className="text-xs font-medium rounded-full bg-white px-2 py-1">{groupName.slice(0, 1)}</div>
      <div className="text-xs font-medium leading-tight">{groupName}</div>
    </div>
  );
};

export const ArtifactGroupItem = (
  { group, messageId, threadId }: { group: MessageGroupedArtifact; messageId: number; threadId: number },
) => {
  return (
    <div key={group.groupName} className="space-y-2 pb-6">
      <GroupTitle group={group} messageId={messageId} threadId={threadId} />
      {group.artifacts.map((artifact) => (
        <ArtifactItem
          messageId={messageId}
          threadId={threadId}
          key={artifact.id}
          artifact={artifact}
        />
      ))}
    </div>
  );
};
