import { Button } from "@/components/ui/button.tsx";
import { Clock } from "lucide-react";
import { useEffect, useState } from "react";

type CountdownButtonProps = {
  expiresAt: Date;
  onComplete: () => void;
  onCancel: () => void;
  isMutating: boolean;
};

enum ButtonState {
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export const CountdownButton = ({
  expiresAt,
  onComplete,
  onCancel,
  isMutating,
}: CountdownButtonProps) => {
  const duration = new Date(expiresAt).getTime() - Date.now();
  const [timeLeft, setTimeLeft] = useState(duration);
  const [state, setState] = useState(ButtonState.ACTIVE);

  useEffect(() => {
    if (state !== ButtonState.ACTIVE || timeLeft <= 0) return;

    const interval = setInterval(() => {
      const timeLeft = new Date(expiresAt).getTime() - Date.now();
      if (timeLeft <= 1) {
        clearInterval(interval);
        handleSendNow();
        return 0;
      }
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [state, timeLeft, onComplete]);

  const handleSendNow = () => {
    setState(ButtonState.COMPLETED);
    setTimeLeft(0);
    onComplete();
  };

  const handleCancel = () => {
    setState(ButtonState.CANCELLED);
    setTimeLeft(0);
    onCancel();
  };

  const minutes = Math.floor(timeLeft / 1000 / 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);
  let formattedTime = "";
  if (minutes > 0 || seconds > 0) {
    formattedTime = `Sending in ${minutes.toString().padStart(2, "0")}:${
      seconds
        .toString()
        .padStart(2, "0")
    }`;
  } else if (state === ButtonState.COMPLETED) {
    formattedTime = "Sent";
  } else if (state === ButtonState.CANCELLED) {
    formattedTime = "Cancelled";
  }

  return (
    <div className="flex items-center gap-4">
      <Button
        disabled={isMutating}
        className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full flex items-center gap-2"
        onClick={handleSendNow}
      >
        <span>{formattedTime}</span>
        <Clock className="w-4 h-4" />
      </Button>
      <Button
        disabled={isMutating}
        variant="ghost"
        onClick={handleCancel}
      >
        Cancel Send
      </Button>
    </div>
  );
};
