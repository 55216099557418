import Markdown from "@/components/markdown/Markdown.tsx";
import { formatDateForMessageDetail } from "@/components/messages/util.ts";
import SourcesList from "@/components/sources/SourcesList.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { createBatchManager, useReadState } from "@/hooks/useReadState.tsx";
import { axiosPostFetch } from "@/lib/fetchers.ts";
import { cn } from "@/lib/utils.ts";
import type { GetMessageResp, MessageAuthor } from "@/types/messages.ts";
import { useEffect, useRef, useState } from "react";

const readStateBatch = createBatchManager((ids: number[]) => {
  console.log(`batching read messages: ${ids.join(", ")}`);
  axiosPostFetch(`/a/messages/read`, {
    arg: { messageIds: ids },
  });
});

export const MessageItem = ({ message, author }: { message: GetMessageResp; author?: MessageAuthor }) => {
  const [ref, isRead] = useReadState({ initialValue: message.isRead });
  const isUser = author?.type === "user";

  useEffect(() => {
    if (isRead && !message.isRead) {
      readStateBatch.add(message.id);
      message.isRead = true;
    }
  }, [message, isRead]);

  useEffect(() => {
    return () => readStateBatch.cleanup();
  }, []);

  return (
    <MessageItemWrapper id={`message-${message.id}`}>
      <div ref={ref} id={`message-${message.id}`} className="flex flex-col flex-1 gap-3">
        {message.content
          ? (
            <div className="w-full pb-5">
              {message.type !== "topic"
                ? (
                  <div className="overflow-auto border rounded-xl p-5 h-fit shadow-sm bg-gradient-to-r from-purple-50 to-white">
                    <Markdown>{message.content}</Markdown>
                  </div>
                )
                : (
                  <div
                    className={cn([
                      "p-2 pt-0",
                      isUser ? "p-4 bg-gray-100 rounded-2xl ml-auto" : "",
                    ])}
                  >
                    <Markdown className="prose">{message.content}</Markdown>
                  </div>
                )}
            </div>
          )
          : null}
      </div>
      <Accordion type="single" collapsible>
        <AccordionItem value={message.id.toString()} className="border-0">
          <div className="flex flex-row items-center text-gray-400 gap-2 text-xs justify-end invisible group-hover/message:visible">
            {!isUser
              ? (
                <>
                  <AccordionTrigger className="p-0">
                    <p className="">Source</p>
                  </AccordionTrigger>
                  <Separator orientation="vertical" className="h-4" />
                </>
              )
              : undefined}
            <p>{formatDateForMessageDetail(message.receivedAt)}</p>
          </div>
          <AccordionContent>
            <SourcesList message={message} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </MessageItemWrapper>
  );
};

/** Apply a little UI flair to the message item load in */
export const MessageItemWrapper = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const rowRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (rowRef.current) {
      setIsLoaded(true);
    }
  }, []);
  return (
    <div
      ref={rowRef}
      id={id}
      className={`mb-2 transition-opacity duration-200 ease-in-out group/message ${
        isLoaded ? "opacity-100" : "opacity-0"
      } flex flex-col`}
    >
      {children}
    </div>
  );
};

export const LoadingMessage = () => (
  <div className="p-4 rounded-md flex flex-row gap-4">
    <Avatar className="w-6 h-6">
      <AvatarImage src="/ai_avatar.svg" />
      <AvatarFallback className="bg-white text-black">AI</AvatarFallback>
    </Avatar>
    <div className="w-full">
      <div className="p-2 rounded-md bg-white">
        <div className="flex items-center gap-2">
          <div className="flex space-x-2">
            <div className="w-2 h-2 rounded-full bg-purple-300 animate-bounce [animation-delay:-0.3s]"></div>
            <div className="w-2 h-2 rounded-full bg-purple-400 animate-bounce [animation-delay:-0.15s]"></div>
            <div className="w-2 h-2 rounded-full bg-purple-500 animate-bounce"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
