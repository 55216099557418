import { axiosPostFetch } from "@/lib/fetchers.ts";
import type { ApproveArtifactReq, GetArtifactResp } from "@/types/artifacts.ts";
import type { GetMessageResp } from "@/types/messages.ts";
import { useCallback } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

const getArtifactPath = (
  message: Pick<GetMessageResp, "threadId" | "id">,
  artifactId: number,
) => `/a/threads/${message.threadId}/messages/${message.id}/artifacts/${artifactId}/approve`;

/**
 * Fetches the artifact for a message.
 */
export const useArtifact = (
  message: Pick<GetMessageResp, "threadId" | "id">,
  artifactId: number,
  isVisible: boolean,
) => {
  const url = isVisible ? getArtifactPath(message, artifactId) : undefined;
  const { data, error, isLoading } = useSWR<GetArtifactResp>(url);

  return {
    artifact: data,
    isLoading,
    error,
  };
};

/** Mutate the artifact approval status for a message. */
export const useApproveArtifact = (
  message: Pick<GetMessageResp, "threadId" | "id">,
  artifactId: number,
) => {
  // By using the same url/key as we do in useArtifact, we ensure the SWR cache
  // will be invalidated when the mutation is complete.
  const url = getArtifactPath(message, artifactId);

  const { trigger, isMutating } = useSWRMutation(
    url,
    axiosPostFetch<ApproveArtifactReq>,
  );

  const approve = useCallback(async () => {
    await trigger({ approve: true });
  }, [trigger]);

  const cancel = useCallback(async () => {
    await trigger({ approve: false });
  }, [trigger]);

  return {
    approve,
    cancel,
    isMutating,
  };
};
