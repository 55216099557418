import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { useState } from "react";

// Snooze enum type
enum SnoozeTime {
  InOneHour = "In one hour",
  LaterToday = "Later today",
  Tomorrow = "Tomorrow",
  PickDateTime = "Pick date & time",
}

function getSnoozeDate(snoozeTime: SnoozeTime) {
  const date = new Date();
  const tenSeconds = 10 * 1000;

  switch (snoozeTime) {
    case SnoozeTime.InOneHour:
      date.setTime(date.getTime() + tenSeconds); // TODO: Change to oneHour
      break;

    case SnoozeTime.LaterToday:
      date.setTime(date.getTime() + tenSeconds); // TODO: Figure this out
      break;
    case SnoozeTime.Tomorrow:
      date.setTime(date.getTime() + tenSeconds); // TODO: Figure this out
      break;
    case SnoozeTime.PickDateTime:
      date.setTime(date.getTime() + tenSeconds); // TODO: Figure this out
      break;
  }
  return date;
}

export function SnoozeDialog({
  isDialogOpen,
  setIsDialogOpen,
  handleSnoozeConfirm,
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
  handleSnoozeConfirm: (date: Date) => void;
}) {
  const [snoozeTime, setSnoozeTime] = useState<SnoozeTime>(SnoozeTime.InOneHour);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-sm">Snooze</DialogTitle>
          <Separator className="h-[1px] bg-gray-200" />
          <div className="pt-4">
            <input
              type="text"
              placeholder="Snooze until..."
              className="text-sm w-full rounded-full border border-gray-200 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <DialogDescription className="pt-3 flex flex-wrap gap-2">
            <SnoozeTimeButton
              snoozeOption={SnoozeTime.InOneHour}
              snoozeTime={snoozeTime}
              setSnoozeTime={setSnoozeTime}
            />
            <SnoozeTimeButton
              snoozeOption={SnoozeTime.LaterToday}
              snoozeTime={snoozeTime}
              setSnoozeTime={setSnoozeTime}
            />
            <SnoozeTimeButton
              snoozeOption={SnoozeTime.Tomorrow}
              snoozeTime={snoozeTime}
              setSnoozeTime={setSnoozeTime}
            />
            <SnoozeTimeButton
              snoozeOption={SnoozeTime.PickDateTime}
              snoozeTime={snoozeTime}
              setSnoozeTime={setSnoozeTime}
            />
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-2">
          <button
            className="rounded-full bg-gray-100 pt-2 pb-2 pl-3 pr-3"
            onClick={() => handleSnoozeConfirm(getSnoozeDate(snoozeTime))}
          >
            Submit
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function SnoozeTimeButton({
  snoozeOption,
  snoozeTime,
  setSnoozeTime,
}: {
  snoozeOption: SnoozeTime;
  snoozeTime: SnoozeTime;
  setSnoozeTime: (snoozeTime: SnoozeTime) => void;
}) {
  const isSelected = snoozeTime === snoozeOption;
  const backgroundColor = isSelected ? "bg-gray-200" : "";
  return (
    <button
      className={`text-gray-700 rounded-full outline outline-gray-200 pt-2 pb-2 pl-3 pr-3 ${backgroundColor}`}
      onClick={() => setSnoozeTime(snoozeOption)}
    >
      {snoozeOption}
    </button>
  );
}
