import { isSameDay, isSameYear, subDays } from "date-fns";
import { useMemo } from "react";

/**
 * This component is used to render a date header in the list.
 */
export const TimestampHeader = (
  { timestamp, prevTimestamp }: { timestamp: string; prevTimestamp?: string },
) => {
  if (prevTimestamp && isSameDay(timestamp, prevTimestamp)) {
    return null;
  }
  const formattedDate = useMemo(() => formatDate(timestamp), [timestamp]);

  return (
    <div className="mb-2">
      <div className="flex flex-row items-center justify-center px-16 py-3">
        <div className="justify-center py-0.5 px-2.5 rounded-full w-fit text-xs font-semibold text-muted-foreground bg-gray-200">
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

const formatDate = (timestamp: string) => {
  const now = new Date();
  if (isSameDay(timestamp, now)) {
    return "Today";
  }
  if (isSameDay(timestamp, subDays(now, 1))) {
    return "Yesterday";
  }

  if (isSameYear(timestamp, now)) {
    // "Sun, Jan 26"
    return new Date(timestamp).toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
  }
  // "Sun, Jan 26, 2025"
  return new Date(timestamp).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
