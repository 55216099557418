import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { ScreenComingSoon } from "../components/ScreenComingSoon.tsx";

export const Settings = () => {
  return (
    <ViewWrapper
      title="Settings"
      titleClassName="text-xl font-semibold tracking-tight"
    >
      <ScreenComingSoon />
    </ViewWrapper>
  );
};
