/**
 * Custom UI components for the right sidebar.
 *
 * @see https://www.figma.com/design/hJNJ984SwXCkZCuk7Een9x/BBL---Design-System?node-id=7137-35663&m=dev
 */

import { Button } from "@/components/ui/button.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { SidebarHeader } from "@/components/ui/sidebar.tsx";
import { X } from "lucide-react";

export const SidebarTextSubtitle = (
  { className, ...props }: React.PropsWithChildren<React.HTMLAttributes<HTMLParagraphElement>>,
) => {
  return <p className={`text-muted-foreground text-xs font-semibold ${className}`} {...props} />;
};

export const SidebarTextHeading = (
  { className, ...props }: React.PropsWithChildren<React.HTMLAttributes<HTMLParagraphElement>>,
) => {
  return (
    <p
      className={`text-accent-foreground font-semibold underline decoration-solid text-underline-offset-auto text-underline-position-from-font hover:no-underline ${className}`}
      {...props}
    />
  );
};

export const SidebarTextBody = (
  { className, ...props }: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>,
) => {
  return <div className={`text-base-foreground font-normal ${className}`} {...props} />;
};

/**
 * @see https://www.figma.com/design/hJNJ984SwXCkZCuk7Een9x/BBL---Design-System?node-id=7210-9612&m=dev
 */
export const RightSidebarHeader = (
  { title, CTA, handleClose }: { title: string; CTA?: React.ReactNode; handleClose: () => void },
) => {
  return (
    <SidebarHeader className="flex flex-row items-center justify-between p-6 border-b">
      <h2 className="text-lg font-semibold">
        {title}
      </h2>
      <div className="flex items-center">
        {CTA && (
          <>
            {CTA}
            <Separator orientation="vertical" className="mx-2 h-4" />
          </>
        )}
        <Button
          variant="ghost"
          size="icon"
          onClick={handleClose}
          className="h-8 w-8"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </div>
    </SidebarHeader>
  );
};
