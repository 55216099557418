import { ContactItem } from "@/components/contacts/ContactItem.tsx";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { RightSidebarHeader } from "@/components/right-sidebar/elements.tsx";
import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import { useContact, useThreadParticipants } from "@/hooks/useContacts.tsx";
import { useLocation } from "wouter";
export const ContactListSidebar = ({ threadId, contactId }: { threadId?: number; contactId?: string }) => {
  const { closeSidebar } = useRightSidebar();
  const { participants, isLoading, error } = useThreadParticipants(threadId);
  const { contact, isLoading: isContactLoading, error: contactError } = useContact(contactId);
  const [_, setLocation] = useLocation();
  if (isLoading || isContactLoading) {
    return <Loader />;
  }

  if (error || contactError) {
    return <Error error={error} />;
  }

  let participantsToRender = participants;
  if (participants.length === 0 && contact) {
    participantsToRender = [contact];
  }

  return (
    <>
      <RightSidebarHeader title="People" handleClose={closeSidebar} />
      <SidebarContent className="py-2">
        {participantsToRender.map((participant) => (
          <div
            key={participant.id}
            className="hover:cursor-pointer"
            onClick={() => setLocation(`/contacts/${participant.id}`)}
          >
            <ContactItem contact={participant} />
          </div>
        ))}
      </SidebarContent>
    </>
  );
};
