/**
 * Used to enforce exhaustive conditional checks.
 *
 * @see https://2ality.com/2020/02/typescript-exhaustiveness-checks-via-exceptions.html
 */
export class UnsupportedValueError extends Error {
  constructor(value: never, message = `Unsupported value: ${value}`) {
    super(message);
  }
}

/**
 * Similar to `UnsupportedValueError`, but logs a warning instead of throwing an error.
 */
export const warnUnsupportedValue = (value: never, message = `Unsupported value: ${value}`) => {
  console.warn(message);
};
