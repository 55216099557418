import { type Contact } from "@/types/contact.ts";
import useSWR from "swr";

export const useContact = (contactId: string | undefined) => {
  const { data, isLoading, error } = useSWR<Contact>(
    contactId ? `/a/contacts/${contactId}` : null,
  );

  return {
    contact: data,
    isLoading,
    error,
  };
};

export const useThreadParticipants = (threadId: number | undefined) => {
  const { data, isLoading, error } = useSWR<Contact[]>(
    threadId ? `/a/threads/${threadId}/participants` : null,
  );

  return {
    participants: data ?? [],
    isLoading,
    error,
  };
};
