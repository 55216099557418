import Markdown from "@/components/markdown/Markdown.tsx";
import { type EmailArtifact } from "@/types/artifacts.ts";

type EmailArtifactItemProps = {
  email: NonNullable<EmailArtifact>;
};

export const EmailArtifactItem = (
  { email }: EmailArtifactItemProps,
) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <div className="font-bold min-w-8">From:</div>
        <div>{email.from}</div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="font-bold min-w-8">To:</div>
        <div>{email.to?.join(", ")}</div>
      </div>
      {email.cc && email.cc.length > 0 && (
        <div className="flex flex-row gap-2">
          <div className="font-bold min-w-8">CC:</div>
          <div>{email.cc?.join(", ")}</div>
        </div>
      )}
      {email.bcc && email.bcc.length > 0 && (
        <div className="flex flex-row gap-2">
          <div className="font-bold min-w-8">BCC:</div>
          <div>{email.bcc?.join(", ")}</div>
        </div>
      )}
      <div className="flex flex-row gap-2">
        <div className="font-bold min-w-8">Subject:</div>
        <div>{email.subject}</div>
      </div>
      <div className="font-bold min-w-8">Body:</div>
      <Markdown>{email.body}</Markdown>
    </div>
  );
};
