import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { sidebarSchema } from "@/components/right-sidebar/sidebarSchema.ts";
import { cn, getApiUrl } from "@/lib/utils.ts";
import ReactMarkdown from "markdown-to-jsx";
import { useLocation } from "wouter";
import { z } from "zod";

export const Markdown = ({
  className,
  children,
  options = {},
  ...props
}: {
  children: React.ReactNode;
  options?: any;
  className?: string;
}) => {
  const defaultOptions = {
    ...options,
    forceBlock: true,
    overrides: {
      a: {
        component: MarkdownLink,
      },
      p: {
        component: MarkdownParagraph,
      },
    },
  };

  const content = Array.isArray(children) ? children.join("") : String(children || "");

  return (
    <span className={cn("prose", className)}>
      <ReactMarkdown options={defaultOptions} {...props}>
        {content}
      </ReactMarkdown>
    </span>
  );
};

const MarkdownParagraph = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <p className="leading-normal">{children}</p>;
};

const MarkdownLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const entityItems = href.split(":");
  const entity = entityItems.at(-2);
  const entityId = entityItems.at(-1);

  let appHref = href;
  switch (entity) {
    case "contact":
    case "company":
    case "person":
    case "user":
      appHref = `/contacts/${entityId}`;
      return getLink(appHref, children, {
        type: "contactList",
        contactId: entityId || "",
      });

    case "source":
      appHref = `/sources/${entityId}`;
      return getLink(appHref, children);

    case "attachment":
      return getAttachmentImage(entityId!);

    default:
      return (
        <a href={href} className="underline" target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
  }
};

function getLink(appHref: string, children: React.ReactNode, sidebarContent?: z.infer<typeof sidebarSchema>) {
  const { openSidebar } = useRightSidebar();
  const [_, setLocation] = useLocation();
  const handleClick = () => {
    if (sidebarContent) {
      openSidebar(sidebarContent);
    } else {
      setLocation(appHref);
    }
  };

  return (
    <span className="underline inline-block hover:cursor-pointer" onClick={handleClick}>
      {children}
    </span>
  );
}

function getAttachmentImage(threadAttachmentId: string) {
  // const [isImageLoaded, setIsImageLoaded] = useState(false);
  // const [isImageError, setIsImageError] = useState(false);

  // const handleImageLoad = () => {
  //   setIsImageLoaded(true);
  //   setIsImageError(false);
  // };

  // const handleImageError = () => {
  //   setIsImageLoaded(false);
  //   setIsImageError(true);
  // };

  const [threadId, attachmentId] = threadAttachmentId.split("-");
  const imageUrl = `${getApiUrl()}/a/threads/${threadId}/raw_inline_images/${attachmentId}`;
  // const hideImage = isImageError || !isImageLoaded;
  return (
    <img
      src={imageUrl}
      // onLoad={handleImageLoad}
      // onError={handleImageError}
      className={"h-48 w-auto"}
    />
  );
}

export default Markdown;
