import { selectedStatusFilterAtom, selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { ChatTextarea } from "@/components/messages/ChatTextarea.tsx";
import ThreadList from "@/components/threads/ThreadList.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { type ThreadStatus, threadStatus } from "@/types/thread.ts";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { useCallback } from "react";

const statusFilters = [
  { value: threadStatus.enum["to-review"], label: "To Review" },
  { value: threadStatus.enum["in-progress"], label: "In Progress" },
  { value: threadStatus.enum["snoozed"], label: "Snoozed" },
  { value: threadStatus.enum["completed"], label: "Done" },
];

export const Threads = () => {
  const [selectedStatus, setSelectedStatus] = useAtom(selectedStatusFilterAtom);
  const [_, setSelectedThread] = useAtom(selectedThreadAtom);
  const { threadPages } = useThreads();

  const handleStatusChange = useCallback(
    (status: ThreadStatus) => {
      setSelectedStatus(status);
      setSelectedThread(threadPages?.[0]?.[0] || null);
    },
    [setSelectedThread, threadPages],
  );

  return (
    <ViewWrapper>
      <div className="flex flex-col gap-2 lg:mt-2 lg:max-w-[48rem] relative">
        <div className="flex flex-col gap-4 basis-1/8">
          <h1 className={`text-4xl fond-extrabold`}>Threads</h1>
          <Tabs defaultValue={selectedStatus}>
            <TabsList className="rounded-full">
              {statusFilters.map((status) => (
                <TabsTrigger
                  key={status.value}
                  value={status.value}
                  onClick={() => handleStatusChange(status.value)}
                  className="rounded-full"
                >
                  {status.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <Separator className="h-[1px] bg-gray-200" />
        </div>
        <div className="flex-grow overflow-y-hidden">
          <ThreadList />
        </div>
        <div className="mt-auto fixed bottom-0 w-full">
          <ChatTextarea className="flex-grow pt-4" onEnterKeyPressed={() => {}} />
        </div>
      </div>
    </ViewWrapper>
  );
};
export default Threads;
