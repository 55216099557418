/**
 * Hooks for interacting with the right sidebar state.
 *
 * Generally, we try to abstract the state management layer in here, the callers should be able to think about
 * the data they want, vs managing internals like query params.
 */

import { useCallback, useMemo } from "react";
import { useLocation, useSearch } from "wouter";
import { z } from "zod";
import { sidebarSchema } from "./sidebarSchema.ts";

const sidebarQueryParamPrefix = "sb_";

/**
 * Given a URLSearchParams object, return the sidebar state.
 */
const getSidebarStateFromQueryParams = (queryParams: URLSearchParams) => {
  // If there is no sidebar type param, the sidebar is not open, don't try to zod parse, bail immediately
  if (!queryParams.has(`${sidebarQueryParamPrefix}type`)) {
    return null;
  }

  // else, the intention is to render the sidebar, parse the query params and ensure they are valid
  const queryParamsEntries = Object.fromEntries(
    queryParams.entries().map(([key, value]) => [key.replace(sidebarQueryParamPrefix, ""), value]),
  );

  const parsed = sidebarSchema.safeParse(queryParamsEntries);
  if (!parsed.success) {
    console.error("Invalid sidebar state", parsed.error);
    return null;
  }

  return parsed.data;
};

/**
 * Hook to get the current sidebar state based on the URL query params.
 *
 * Primarily intended to be used by the RightSidebar component itself.
 */
export const useRightSidebarQueryParamState = () => {
  // Get the sidebar state based on the URL query params
  const urlQueryParams = useSearch();
  const queryParamSidebarState = useMemo(() => {
    const queryParams = new URLSearchParams(urlQueryParams);
    return getSidebarStateFromQueryParams(queryParams);
  }, [urlQueryParams]);

  return queryParamSidebarState;
};

/**
 * Hook to open and close the right sidebar.
 *
 * The sidebar is opened by setting the query params, and closed by removing the query params.
 * The component listens to the URL change and will respond.
 */
export const useRightSidebar = () => {
  const [location, setLocation] = useLocation();
  const searchParams = useSearch();

  /** To open the sidebar, set its query params. The component listens to the URL change and will respond. */
  const openSidebar = useCallback((sidebarContent: z.infer<typeof sidebarSchema>) => {
    const queryParams = new URLSearchParams(searchParams); // keep the existing query params

    // Update the query params. Add a prefix to each of these (the consumer will strip these prefixes out)
    Object.entries(sidebarContent).forEach(([key, value]) => {
      queryParams.set(`${sidebarQueryParamPrefix}${key}`, value.toString());
    });
    queryParams.sort(); // cosmetic

    // Write the query params (both our new ones and the originals) to the URL
    setLocation(`?${queryParams.toString()}`, {
      replace: true,
    });
  }, [setLocation]);

  /** To close the sidebar, remove all sidebar related query params. */
  const closeSidebar = useCallback(() => {
    const queryParams = new URLSearchParams(searchParams);

    // need to get this list before the mutative loop below, or it won't remove everything
    const queryParamsArray = [...queryParams.keys()];

    // Remove all sidebar query params
    for (const key of queryParamsArray) {
      if (key.startsWith(sidebarQueryParamPrefix)) {
        queryParams.delete(key);
      }
    }
    setLocation(queryParams.size !== 0 ? `?${queryParams.toString()}` : location, {
      replace: true,
    });
  }, [setLocation]);

  return {
    openSidebar,
    closeSidebar,
  };
};
