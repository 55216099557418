import { type Contact } from "@/types/contact.ts";
import useSWR from "swr";

export const useContact = (contactId: string) => {
  const { data, isLoading, error } = useSWR<Contact>(
    `/a/contacts/${contactId}`,
  );

  return {
    contact: data,
    isLoading,
    error,
  };
};

export const useThreadParticipants = (threadId: number) => {
  const { data, isLoading, error } = useSWR<Contact[]>(
    `/a/threads/${threadId}/participants`,
  );

  return {
    participants: data ?? [],
    isLoading,
    error,
  };
};
