import { type GetThreadResp, type ThreadStatus } from "@/types/thread.ts";

export const threadName = (thread: GetThreadResp | undefined) => {
  if (!thread) {
    return "Unknown Thread Type";
  }
  return thread.title;
};

export const formatDateForThreadList = (inputDate: Date): string => {
  if (!inputDate) {
    return "";
  }
  const date = new Date(inputDate);
  const now = new Date();
  if (now.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  } else if (now.getFullYear() === date.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const statusLabels: Record<ThreadStatus, string> = {
  "to-review": "To Review",
  "in-progress": "In Progress",
  snoozed: "Snoozed",
  completed: "Done",
};

export const formatThreadStatus = (status: ThreadStatus) => {
  return statusLabels[status];
};
