import { useSources } from "@/hooks/useSources.tsx";
import { getApiUrl } from "@/lib/utils.ts";
import type { GetMessageResp } from "@/types/messages.ts";
import type { ListSourcesResp } from "@/types/sources.ts";
import type { LucideIcon } from "lucide-react";
import { Brain, Mail, Paperclip } from "lucide-react";
import React from "react";
import { Link } from "wouter";

// Types
type Source = NonNullable<ListSourcesResp>;
type DisplaySource = Source & { icon: LucideIcon };
type Attachment = NonNullable<DisplaySource["attachments"]>[0];

// Utility Functions
function getRealAttachments(attachments: DisplaySource["attachments"]): Attachment[] {
  if (!attachments) return [];

  return attachments.filter((attachment) => {
    const extension = attachment.name?.toLowerCase().split(".").pop();
    if (!extension) return false;

    const knownImageExtensions = ["gif", "png", "jpg", "jpeg"];
    return !knownImageExtensions.includes(extension);
  });
}

function getSourceIcon(type: string): LucideIcon {
  switch (type) {
    case "document":
      return Paperclip;
    case "email":
      return Mail;
    case "memory":
      return Brain;
    default:
      return Paperclip;
  }
}

// AttachmentLink Component
interface AttachmentLinkProps {
  threadId: number;
  attachment: Attachment;
}

const AttachmentLink: React.FC<AttachmentLinkProps> = ({ threadId, attachment }) => {
  const href = `${getApiUrl()}/a/threads/${threadId}/raw_attachments/${attachment.id}`;

  return (
    <div className="flex flex-row items-center gap-2 pl-4 pt-1">
      <Paperclip className="w-4 h-4" />
      <a href={href} target="_blank" rel="noopener noreferrer">{attachment.name}</a>
    </div>
  );
};

// SourceLink Component
interface SourceLinkProps {
  messageId: number;
  threadId: number;
  source: DisplaySource;
}

const SourceLink: React.FC<SourceLinkProps> = ({ messageId, threadId, source }) => {
  const Icon = source.icon;

  return (
    <div className="flex flex-row items-center gap-2">
      <Icon className="w-4 h-4" />
      <Link href={`/threads/${threadId}/messages/${messageId}/sources/${source.id}`}>
        {source.title}
      </Link>
    </div>
  );
};

// Main SourcesList Component
interface SourcesListProps {
  message: GetMessageResp;
}

const SourcesList: React.FC<SourcesListProps> = ({ message }) => {
  const { sources } = useSources({
    threadId: message.threadId,
    messageId: message.id,
  });

  if (!sources) return null;

  const displaySources: DisplaySource[] = sources.map((source) => ({
    ...source,
    icon: getSourceIcon(source.type),
  }));

  return (
    <div className="flex flex-col gap-2 text-black text-xs w-full">
      {displaySources.map((source) => (
        <div key={source.id} className="flex flex-row items-center gap-2">
          <div className="flex flex-col">
            <SourceLink
              messageId={message.id}
              threadId={message.threadId}
              source={source}
            />
            {getRealAttachments(source.attachments).map((attachment) => (
              <AttachmentLink
                key={attachment.id}
                threadId={message.threadId}
                attachment={attachment}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SourcesList;
