import { ContactItem } from "@/components/contacts/ContactItem.tsx";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { RightSidebarHeader } from "@/components/right-sidebar/elements.tsx";
import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import { useThreadParticipants } from "@/hooks/useContacts.tsx";
export const ContactListSidebar = ({ threadId }: { threadId: number }) => {
  const { openSidebar, closeSidebar } = useRightSidebar();
  const { participants, isLoading, error } = useThreadParticipants(threadId);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <RightSidebarHeader title="People" handleClose={closeSidebar} />
      <SidebarContent className="py-2">
        {participants.map((participant) => (
          <div
            key={participant.id}
            className="hover:cursor-pointer"
            onClick={() => openSidebar({ type: "contact", contactId: participant.id })}
          >
            <ContactItem contact={participant} />
          </div>
        ))}
      </SidebarContent>
    </>
  );
};
