import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import type { SetListItemVisibility } from "@/hooks/useMessageList.tsx";
import { cn } from "@/lib/utils.ts";
import type { GetMessageListResp, MessageAuthor } from "@/types/messages.ts";
import type React from "react";
import { useInView } from "react-intersection-observer";

const SenderAvatar = ({ author }: { author?: MessageAuthor }) => {
  const { openSidebar } = useRightSidebar();
  if (author?.type !== "assistant" && author?.type !== "person") {
    return null;
  }

  const id = author?.urn.split(":")[3];

  return (
    <Avatar className="w-full h-6 border" onClick={() => openSidebar({ type: "contact", contactId: id })}>
      <AvatarImage src={author.picture ?? "/ai_avatar.png"} />
      <AvatarFallback className="bg-white text-black">
        {author?.name[0] || ""}
      </AvatarFallback>
    </Avatar>
  );
};

/**
 * A message list item row.
 *
 * This is a wrapper around a message row that ensures a consistent layout between items types.
 */
export const MessageListRow = (
  { author, children, item, scrollContainerRef, scrollTargetRef, sticky = false, setListItemVisibility }: {
    author?: MessageAuthor;
    children: React.ReactNode;
    item: GetMessageListResp["items"][number];
    sticky?: boolean;
    /** ref to enable us to scroll to this item */
    scrollTargetRef?: React.RefObject<HTMLDivElement>;
    /** Ref on the parent scroll container */
    scrollContainerRef: React.RefObject<HTMLElement>;
    setListItemVisibility: SetListItemVisibility;
  },
) => {
  // Stickiness needs to be specified at the top level row level to work for the whole list.
  // pointer-events-none is used to avoid with selecting text under the sticky item or its invisible background.
  const stickyClassName = sticky ? "sticky top-[-1px] pointer-events-none z-10" : "";

  const [listItemVisibleRef] = useInView({
    // This is the percentage of the item that is in view (not % of view taken up by the item).
    // So for very tall items on small screens, it may be impossible to achieve something like 80% visibility,
    // which means we would never treat the item as viewed.
    // For now we just set this to be low, but to make it more robust we may want to do some math in the onChange handler,
    // or switch approches.
    threshold: 0.1,
    rootMargin: "0px",

    // out of the box, sticky items are considered always visible. There are workarounds, but we don't actually care about
    // the current set of sticky items, so we just ignore them for simplicity.
    skip: sticky,
    root: scrollContainerRef.current,
    onChange: (itemInView) => {
      setListItemVisibility({ itemKey: item.key, isVisible: itemInView });
    },
  });

  return (
    <div ref={listItemVisibleRef} className={cn("flex flex-row gap-3", stickyClassName)}>
      {/* Invisible item for scroll anchoring, lets us avoid merging refs on the parent */}
      {scrollTargetRef && <div ref={scrollTargetRef} className="visibility-hidden" />}
      {/* left rail, consistent width for all messages */}
      <div className="flex flex-col w-6 flex-shrink-0">
        {!!author && <SenderAvatar author={author} />}
      </div>
      {/* right rail, consumes remaining width. break-words min-w-0 ensures long items don't cause horizontal scroll. */}
      <div className="flex-1 break-words min-w-0">
        {children}
      </div>
      {/* spacer to balance the left rail */}
      <div className="w-6 flex-shrink-0" />
    </div>
  );
};
MessageListRow.displayName = "MessageListItemRow";
