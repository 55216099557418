import { selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { formatDateForThreadList } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { type GetThreadResp, threadStatus, threadType } from "@/types/thread.ts";
import { useAtom } from "jotai";
import { AlarmClockPlus } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "wouter";
import { Markdown } from "../markdown/Markdown.tsx";
const ThreadCards = () => {
  const [selectedThread, setSelectedThread] = useAtom(selectedThreadAtom);
  const {
    threadPages,
    isLoading: isThreadsLoading,
    error: threadsError,
  } = useThreads({
    status: threadStatus.enum["to-review"],
    type: threadType.enum["topics"],
    limit: 6,
  });
  const [_location, setLocation] = useLocation();

  useHotkeys("j", () => {
    let nextThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        nextThread = filteredThreads[i + 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(nextThread);
  });

  useHotkeys("k", () => {
    let previousThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        previousThread = filteredThreads[i - 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(previousThread);
  });

  useHotkeys("enter", () => {
    if (selectedThread) {
      handleClick(selectedThread);
    }
  });

  const handleClick = useCallback(
    (thread: GetThreadResp) => {
      setSelectedThread(thread);
      setLocation(`/threads/${thread.id}`);
    },
    [setSelectedThread],
  );

  const filteredThreads = useMemo(() => threadPages.flat(), [threadPages]);

  if (threadsError) {
    return <Error error={threadsError} />;
  }

  if (isThreadsLoading) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {filteredThreads.map((thread) => (
        <div
          key={thread.id}
          className={`${selectedThread?.id === thread.id ? "" : "border-none"}
            flex-1 bg-white min-w-full lg:w-[20rem] min-h-80 max-h-80 lg:min-w-64 lg:max-w-86 rounded-2xl overflow-hidden`}
        >
          <div
            className="relative p-4 bg-center hover:cursor-pointer min-w-full lg:min-w-64 lg:max-w-86 h-full rounded-2xl border"
            onClick={() => handleClick(thread)}
          >
            <div className="relative flex flex-col min-h-full">
              <div className="flex h-6 flex-row items-center">
                {thread.formattedLabels
                  ?.filter((label) => label)
                  .map((label) => (
                    <Badge
                      key={label.name}
                      className="bg-gray-200 text-black"
                      style={{
                        backgroundColor: label.backgroundColor,
                        color: label.textColor,
                      }}
                    >
                      {label.name}
                    </Badge>
                  ))}
                {thread.unreadCount > 0 && (
                  <Badge className="bg-gray-200 text-black">
                    {thread.unreadCount}
                  </Badge>
                )}
                <AlarmClockPlus className="w-4 h-4 ml-auto" />
              </div>
              <div className="flex flex-col relative pt-20 mt-auto">
                <div className="mt-auto flex-none flex flex-col relative z-10 pb-3">
                  <div className="font-bold text-ellipsis overflow-hidden whitespace-nowrap">
                    {thread.title}
                  </div>
                  <div className="text-xs text-muted font-medium leading-none opacity-75">
                    {formatDateForThreadList(thread.lastReceivedAt)}
                  </div>
                </div>
                <div className="overflow-hidden relative z-10">
                  <Markdown className="prose-sm line-clamp-3 leading-tight">
                    {thread.summary}
                  </Markdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreadCards;
