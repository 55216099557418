import type { GetMessageSourceResp } from "@/types/sources.ts";
import DOMPurify from "dompurify";

const EmailDetail = ({ source }: { source: GetMessageSourceResp }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col border rounded-md p-2">
        <div className="flex flex-row items-center gap-2">
          <p>From: {source.email?.from}</p>
        </div>
        <div className="flex flex-row items-center gap-2">
          <p>To: {source.email?.to}</p>
        </div>
        <p>Subject: {source.email?.subject}</p>
        <p>Date: {new Date(source.email?.receivedAt ?? new Date()).toLocaleString() ?? "N/A"}</p>
      </div>
      <div className="flex flex-col border rounded-md p-2">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(source.email?.body ?? ""),
          }}
        />
      </div>
    </div>
  );
};

export default EmailDetail;
