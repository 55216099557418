import type { GetMessageSourceResp } from "@/types/sources.ts";
import useSWR from "swr";

export interface UseSourceParams {
  threadId: number;
  messageId: number;
  sourceId: number;
}

export const useSource = ({
  sourceId,
  threadId,
  messageId,
}: UseSourceParams) => {
  const { data, error, isLoading } = useSWR<GetMessageSourceResp>(
    `/a/threads/${threadId}/messages/${messageId}/sources/${sourceId}`,
  );
  return {
    error: error,
    isLoading: isLoading,
    source: data,
  };
};

export interface UseSourcesParams {
  threadId: number;
  messageId: number;
}

export const useSources = ({
  threadId,
  messageId,
}: UseSourcesParams) => {
  const { data, error, isLoading } = useSWR<GetMessageSourceResp[]>(
    `/a/threads/${threadId}/messages/${messageId}/sources`,
  );
  return {
    error: error,
    isLoading: isLoading,
    sources: data,
  };
};
