import Markdown from "@/components/markdown/Markdown.tsx";
import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip.tsx";
import type { GetArtifactResp } from "@/types/artifacts.ts";
import { ArrowRight, CircleArrowOutUpRight, Copy, Dot, MessageCircleMore, Share2 } from "lucide-react";
import { useCallback } from "react";
import { Link } from "wouter";
export const UpdateArtifactItem = ({
  messageId,
  threadId,
  artifact,
  update,
}: {
  messageId: number;
  threadId: number;
  artifact: GetArtifactResp;
  update: NonNullable<GetArtifactResp["update"]>;
}) => {
  const { openSidebar } = useRightSidebar();

  const handleOpenSidebar = useCallback(() => {
    openSidebar({
      type: "domainSidebar",
      messageId,
      threadId,
      domainId: update.domain?.id,
    });
  }, [openSidebar, update.id]);

  return (
    <div className="flex flex-col-reverse lg:flex-row gap-5">
      <div className="basis-full flex justify-start flex-col gap-3 group/update">
        <div className="flex flex-row text-lg items-center font-semibold leading-7
                  decoration-solid text-underline-offset-auto
                  text-underline-position-from-font">
          {update.title}
          {!artifact.isRead && <Dot size={24} className="text-blue-500" />}
        </div>
        <div className="">
          <Markdown className="leading-normal">{update.summary}</Markdown>
        </div>
        <div className="border rounded-full text-sm px-2 py-0.5 w-fit" onClick={handleOpenSidebar}>
          {update.domain?.name}
        </div>
        <div
          className={`flex flex-row flex-start gap-2 text-xs text-muted-foreground invisible group-hover/update:visible`}
        >
          <Tooltip>
            <TooltipTrigger>
              <MessageCircleMore
                size={16}
              />
            </TooltipTrigger>
            <TooltipContent>Sidebar</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <Copy size={16} />
            </TooltipTrigger>
            <TooltipContent>Copy</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <Share2 size={16} />
            </TooltipTrigger>
            <TooltipContent>Share</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <CircleArrowOutUpRight size={16} />
            </TooltipTrigger>
            <TooltipContent>New Thread</TooltipContent>
          </Tooltip>

          <div className="ml-auto">
            <Link href={`/threads/${threadId}/messages/${messageId}/sources/${artifact.source?.id}`}>
              <div className="flex flex-row items-center gap-0.5 text-foreground font-medium">
                <div>Source</div>
                <ArrowRight size={16} />
              </div>
            </Link>
          </div>
        </div>
      </div>
      {
        /* {update.imageUrl && (
        <div className="w-full h-32 lg:w-[16.375rem] lg:h-[13.25rem] flex-none border rounded-2xl">
          <img
            src={update.imageUrl.replace(/&amp;/g, "&")}
            alt="Update image"
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>
      )} */
      }
    </div>
  );
};
