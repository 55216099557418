import { isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import { RightSidebar } from "@/components/right-sidebar/RightSidebar.tsx";
import { Button } from "@/components/ui/button.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { TooltipProvider } from "@/components/ui/tooltip.tsx";
import { useAtom } from "jotai";
import { ChevronLeft } from "lucide-react";
import { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export const ViewWrapper: React.FC<{
  title?: string;
  titleClassName?: string;
  includeBackButton?: boolean;
  onBack?: () => boolean;
  children: React.ReactNode;
  className?: string;
  rightElement?: React.ReactNode;
  allowFullBleed?: boolean;
}> = ({
  title,
  titleClassName,
  includeBackButton = false,
  onBack,
  children,
  className,
  rightElement,
  /** Allow the contents to reach the right edge of the screen. With this set, the screen is responsible for constraining its content width. */
  allowFullBleed = false,
  ...props
}) => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);

  const handleSidebarOpenChange = useCallback(
    (open: boolean) => {
      setIsNavSidebarOpen(open);
    },
    [setIsNavSidebarOpen],
  );

  const handleBackButton = useCallback(() => {
    let goBack = true;
    if (onBack) {
      goBack = onBack();
    }
    if (goBack) {
      window.history.back();
    }
  }, [onBack]);
  useHotkeys("escape", handleBackButton);

  // The negative margin creates a slight overlap to make the fade effect work
  const headerGradientClasses = "bg-gradient-to-t from-transparent to-white to-[4px] mb-[-4px]";
  const headerLayoutClasses = `lg:h-20 sticky top-0 flex-shrink-0 ${headerGradientClasses}`;

  return (
    <TooltipProvider delayDuration={20}>
      <RightSidebar />
      <SidebarProvider
        defaultOpen={false}
        open={isNavSidebarOpen}
        onOpenChange={handleSidebarOpenChange}
        className={`h-full flex flex-row w-full max-w-100 ${className}`}
        style={{
          "--sidebar-width": "14.25rem",
        }}
        {...props}
      >
        <NavSidebar />
        <div className="w-full flex flex-grow flex-row relative">
          {/* Left column. Contains nav sidebar placeholder. lg+ only. */}
          <div
            className={`${headerLayoutClasses}
                max-lg:hidden
                flex items-center justify-center
                transition-all duration-200 ease-linear
                bg-white rounded-none
                ${isNavSidebarOpen ? "lg:min-w-6 opacity-0" : "lg:min-w-24 opacity-100"}
                `}
          >
            <NavSideBarTrigger
              disabled={isNavSidebarOpen}
            />
          </div>

          {/* Center column. Contains main header and content. */}
          <div className="w-full flex flex-col flex-1 isolate">
            {/* Main header. Nested in the center column to ensure its content aligns with main's. Always full bleed to the right. */}
            <header
              className={`${headerLayoutClasses} z-10 flex flex-row items-center ${standardGutterStyles}`}
            >
              {/* md and below, show the trigger in the main header */}
              <NavSideBarTrigger
                className={`lg:hidden transition-all duration-200 ease-linear overflow-hidden w-5 mr-3 ${
                  isNavSidebarOpen ? "w-0 mr-0 disabled:opacity-0" : "opacity-100"
                } `}
                disabled={isNavSidebarOpen}
              />
              {includeBackButton && (
                <Button variant="ghost" size="icon" onClick={handleBackButton} className="">
                  <ChevronLeft className="w-6 h-6" />
                </Button>
              )}
              <div className={`flex-grow ${titleClassName}`}>{title || ""}</div>
              {rightElement}
            </header>
            {/* Main content row */}
            <main
              className={`flex-1 flex flex-col isolate ${
                allowFullBleed ? "" : `lg:max-w-[66.5rem] ${standardGutterStyles}`
              }`}
            >
              {children}
            </main>
          </div>
        </div>
      </SidebarProvider>
    </TooltipProvider>
  );
};

export const standardGutterStyles = "px-4 lg:px-0";
