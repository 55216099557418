import { axiosPostFetch } from "@/lib/fetchers.ts";
import type { NewMessageReq } from "@/types/chat.ts";
import type { GetMessageListResp, TopicMessageItem } from "@/types/messages.ts";
import { useCallback, useMemo, useRef } from "react";
import useSWRInfinite from "swr/infinite";
import useSWRMutation from "swr/mutation";

export type SetListItemVisibility = (params: { itemKey: string; isVisible: boolean }) => void;

/**
 * State management hook to keep track of context we want to pass from the message list to the new message request.
 *
 * Uses a ref under the hood to avoid re-renders.
 *
 * Down the road, we may want to generalize this to be more global, to allow us to tell the server all relevant visible
 * elements, not just the ones in the message list. For now, a local ref is much simpler.
 */
const useChatClientContext = () => {
  const chatContext = useRef<{
    /** Similar to NewMessageReq['messageList'], but we want a Set locally */
    visibleListItemKeys: Set<string>;
  }>({
    visibleListItemKeys: new Set(),
  });

  const setListItemVisibility = useCallback<SetListItemVisibility>(({ itemKey, isVisible }) => {
    if (isVisible) {
      chatContext.current.visibleListItemKeys.add(itemKey);
    } else {
      chatContext.current.visibleListItemKeys.delete(itemKey);
    }
    console.debug("visibleListItems", chatContext.current);
  }, []);

  const getClientContext = useCallback((items: GetMessageListResp["items"]): NewMessageReq["clientContext"] => {
    // Get the deduped list of visible Messages (multiple items may map to the same message)
    const visibleListItemMessageIds = Array.from(
      new Set(
        items
          .filter((item) => chatContext.current.visibleListItemKeys.has(item.key))
          .map((item) => item.messageId),
      ),
    );
    return {
      messageList: {
        visibleListItemKeys: Array.from(chatContext.current.visibleListItemKeys),
        visibleListItemMessageIds,
      },
    };
  }, []);

  return {
    getClientContext,
    setListItemVisibility,
  };
};

/**
 * Handles the state management for and networking for the message list view model.
 */
export const useMessageList = ({
  threadId,
  limit = 10,
}: {
  threadId?: number;
  limit?: number;
}) => {
  const baseUrl = `/a/threads/${threadId}/messages/vm/message-list`;

  const { getClientContext, setListItemVisibility } = useChatClientContext();

  const getMessagesUrl = (pageIndex: number, previousPageData: GetMessageListResp | null) => {
    // Return null if we've reached the end
    if (previousPageData && !previousPageData.nextCursor) return null;

    // First page, no cursor needed
    if (pageIndex === 0) return `${baseUrl}?limit=${limit}`;

    // Add cursor for subsequent pages
    const cursor = previousPageData?.nextCursor;
    return `${baseUrl}?limit=${limit}&cursor=${cursor}`;
  };

  const { trigger, isMutating: isSending } = useSWRMutation(
    `/a/threads/${threadId}/messages`,
    axiosPostFetch<NewMessageReq>,
  );

  const {
    data,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    isValidating,
  } = useSWRInfinite<GetMessageListResp>(
    getMessagesUrl,
    {
      // This will allow us to do things like refresh the list on nav.
      // Note that if we have loaded multiple pages, and this gets back a different cursor on the first page,
      // it will follow the cursors and refresh the other pages (unlike revalidateAll, which will always do that).
      // Unfortunately, it also forces SWR to refetch the first page before fetching new pages on infinite scroll, which is not ideal for load (though may handle some bugs for us).
      // TODO: revisit this if it becomes a load/latency issue. It may be better to have a double cursor model that we can just use to check for newer messages.
      revalidateFirstPage: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: false,
      refreshInterval: 30000,
    },
  );

  const isLoadingMore = size > 0 && data && typeof data[size - 1] === "undefined";
  const hasPreviousPage = Boolean(data?.[data.length - 1]?.nextCursor);

  const loadMore = useCallback(() => {
    // TODO: we need to confirm this can't double pull
    if (!isLoadingMore && hasPreviousPage) {
      setSize((size) => size + 1);
    }
  }, [isLoadingMore, hasPreviousPage, setSize]);

  const items = useMemo(() => {
    const dataItems = data
      // data is an array of all of the pages we've returned, with later fetches at the end
      // since we're scrolling up, with older items above, we need to reverse data.
      // Each of the items in page.items has already been ordered as we'd prefer on the backend,
      // with older messages above newer messages, and their artifact items beneath them, sorted as appropriate
      ?.toReversed()
      ?.flatMap((page) => page.items) ?? [];

    return dataItems;
  }, [data]);

  const sendMessage = useCallback(async (text: string) => {
    if (!threadId) {
      throw new Error("threadId is required");
    }

    // while triggering the response, prepend a fake page (the pages get reversed below, so the latest is on the bottom)
    const fakeId = Math.random();
    const optimisticTopicMessage: TopicMessageItem = {
      itemType: "topicMessage",
      key: `urn:bb:message:${fakeId}`, // TODO: fake URN, use a real one
      message: {
        id: fakeId,
        type: "topic",
        content: text,
        isRead: true,
        threadId,
        receivedAt: new Date().toISOString(),
      },
      messageId: fakeId,
      author: { type: "user", name: "User", urn: "urn:bb:user", contactAddresses: [] },
    };
    const fakePage: GetMessageListResp = {
      items: [optimisticTopicMessage],
      nextCursor: undefined,
    };

    // TODO: polish the chat UI https://linear.app/big-basin-labs/issue/BIG-230/polish-chat-ux-in-threads

    // TODO: why doesn't this work? Feels like this would give us the most safety in terms of potential race conditions,
    // but it never revaldiates.
    // mutate(async (pages) => {
    //   await trigger({ text });
    //   return pages; // ideally this would be a value that opts out of updating the cache
    // }, {
    //   revalidate: true,
    //   optimisticData: (pages) => [fakePage, ...(pages ?? [])],
    // });
    // inject the fake, optimistic data. Since we want its items to be the last items in the DOM, it needs to go first in the page list
    mutate((pages) => [fakePage, ...(pages ?? [])], {
      revalidate: false,
    });
    await trigger({
      text,
      clientContext: getClientContext(items),
    }); // force the mutation
    mutate(); // revalidate - will replace the optimistic data with real server data
  }, [trigger, mutate, getClientContext, items, threadId]);

  return {
    items,
    mutate,
    setListItemVisibility,
    isLoading,
    error,
    isSending,
    sendMessage,
    size,
    setSize,
    isValidating,
    hasPreviousPage,
    isLoadingMore,
    loadMore,
  };
};
