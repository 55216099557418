import { ContactItem } from "@/components/contacts/ContactItem.tsx";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { RightSidebarHeader } from "@/components/right-sidebar/elements.tsx";
import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { SidebarContent } from "@/components/ui/sidebar.tsx";
import { useContact } from "@/hooks/useContacts.tsx";

export const ContactSidebar = ({ contactId }: { contactId: string }) => {
  const { closeSidebar } = useRightSidebar();
  const { contact, isLoading, error } = useContact(contactId);

  if (isLoading || !contact) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <RightSidebarHeader title="People" handleClose={closeSidebar} />
      <SidebarContent className="py-2 px-2">
        <ContactItem contact={contact} />
        <div className="flex flex-col gap-2 mt-4 ml-4">
          <h2 className="text-lg font-bold">Memories</h2>
        </div>
      </SidebarContent>
    </>
  );
};
