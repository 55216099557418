import { messageListScrollPositionAtom } from "@/atoms/messagesAtoms.ts";
import { AvatarGroup } from "@/components/AvatarGroup.tsx";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { MessageList } from "@/components/messages/MessageList.tsx";
import { formatThreadStatus, threadName } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useTopicThread } from "@/hooks/useThreads.tsx";
import type { ThreadStatus } from "@/types/thread.ts";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { CircleCheck } from "lucide-react";
import { useEffect } from "react";
import { useLocation, useRoute } from "wouter";

export const StatusBadge = ({ status }: { status: ThreadStatus }) => {
  // TODO: when design is ready, flesh this out more. Potentially share with ThreadList
  return <Badge className="flex-none">{formatThreadStatus(status)}</Badge>;
};

export const Thread = () => {
  const [_location, setLocation] = useLocation();
  const [matchThread, paramsThread] = useRoute("/threads/:threadId");
  const threadId = parseInt(paramsThread?.threadId || "");
  const [_, setMessageListScrollPosition] = useAtom(
    messageListScrollPositionAtom,
  );
  const {
    error: threadError,
    isLoading: isThreadLoading,
    thread,
    archiveMutation,
  } = useTopicThread({ threadId });

  useEffect(() => {
    if (!matchThread || !paramsThread || !paramsThread.threadId) {
      setLocation("/");
    }
  }, [matchThread, paramsThread, setLocation]);

  const handleBack = () => {
    setMessageListScrollPosition(-1);
    return true;
  };

  const handleMarkAsDone = () => {
    archiveMutation.trigger();
    window.history.back();
  };

  if (!matchThread || !paramsThread || !paramsThread.threadId) {
    return null;
  }

  if (threadError) {
    return <Error error={threadError} />;
  }

  if (isThreadLoading) {
    return <Loader />;
  }

  const participants = thread?.participants;
  const rightHeaderElement = (
    <div className="flex items-center gap-2 pr-2">
      <AvatarGroup threadId={threadId} participants={participants || []} />
      {thread?.status === "to-review"
        ? (
          <Button variant="outline" size="sm" onClick={handleMarkAsDone} disabled={archiveMutation.isMutating}>
            <CircleCheck size={16} />
            <span className="">Mark as done</span>
          </Button>
        )
        : <StatusBadge status={thread?.status || "to-review"} />}
    </div>
  );
  return (
    <ViewWrapper
      includeBackButton={true}
      onBack={handleBack}
      title={threadName(thread)}
      titleClassName="text-xs lg:text-xl font-semibold tracking-tight"
      rightElement={rightHeaderElement}
    >
      <div className="flex-1 flex flex-col pt-14 lg:pt-24">
        <MessageList
          threadId={threadId}
          onEscapeKeyPressed={handleBack}
        />
      </div>
    </ViewWrapper>
  );
};

export default Thread;
