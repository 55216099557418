import { useRightSidebar } from "@/components/right-sidebar/hooks.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import type { GetThreadResp } from "@/types/thread.ts";
type AvatarGroupProps = {
  threadId: number;
  participants: GetThreadResp["participants"];
};

export const AvatarGroup = ({ threadId, participants }: AvatarGroupProps) => {
  const { openSidebar } = useRightSidebar();
  const maxParticipants = 3;
  return (
    <div
      className="flex -space-x-4"
      onClick={() =>
        openSidebar({
          type: "contactList",
          threadId,
        })}
    >
      {participants?.slice(0, maxParticipants).map((participant) => (
        <Avatar key={participant.id} className="border border-black size-8">
          <AvatarImage src={participant.picture ?? undefined} />
          <AvatarFallback>{participant.name.slice(0, 2)}</AvatarFallback>
        </Avatar>
      ))}
    </div>
  );
};
